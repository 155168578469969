import React from 'react';

export default class ModalPoolset extends React.Component {

    changeState(state, e){
        this.props.changeState(state, e.target.value);
    }

    render() {

        return (
            <div id={this.props.id} className="modal fade" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">{this.props.title}</h3>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                {(this.props.id === 'modalAddPoolset')
                                ?   <div className="form-group col-12">
                                        <label>Customer</label>
                                        <select className="custom-select" value={this.props._customerId}
                                        onChange={this.changeState.bind(this, '_customerId')}>
                                            <option value={0}></option>
                                            {(this.props.customers)&&(this.props.customers.map((customer, i)=>{
                                                return <option key={i} value={customer.id}>{customer.company}</option>
                                            }))}                                        
                                        </select>
                                    </div>
                                :   <div className="form-group col-12">
                                        <label>Customer</label>
                                        <select className="custom-select" defaultValue={this.props._customerId}>
                                            {(this.props.customers)&&(this.props.customers.map((customer, i)=>{
                                                if (this.props._customerId === customer.id)
                                                return <option key={i} value={customer.id}>{customer.company}</option>
                                            }))}                                        
                                        </select>
                                    </div>
                                }
                                
                                <div className="form-group col-12">
                                    <label>Name</label>
                                    <input className="form-control" type="text" value={this.props._name}
                                    onChange={this.changeState.bind(this, '_name')} autoComplete="new_name"/>
                                </div>
                                <div className="divider"><span>Pool 1</span></div>
                                <div className="form-group col-12">
                                    <label>Url</label>
                                    <input className="form-control" type="text" value={this.props._pool1Url}
                                    onChange={this.changeState.bind(this, '_pool1Url')} autoComplete="new_pool1Url"/>
                                </div>
                                <div className="form-group col-6 pr-2">
                                    <label>User</label>
                                    <input className="form-control" type="text" value={this.props._pool1User}
                                    onChange={this.changeState.bind(this, '_pool1User')} autoComplete="new_pool1User"/>
                                </div>
                                <div className="form-group col-6 pl-2">
                                    <label>Pass</label>
                                    <input className="form-control" type="text" value={this.props._pool1Pass}
                                    onChange={this.changeState.bind(this, '_pool1Pass')} autoComplete="new_pool1Pass"/>
                                </div>

                                <div className="divider"><span>Pool 2</span></div>
                                <div className="form-group col-12">
                                    <label>Url</label>
                                    <input className="form-control" type="text" value={this.props._pool2Url}
                                    onChange={this.changeState.bind(this, '_pool2Url')} autoComplete="new_pool2Url"/>
                                </div>
                                <div className="form-group col-6 pr-2">
                                    <label>User</label>
                                    <input className="form-control" type="text" value={this.props._pool2User}
                                    onChange={this.changeState.bind(this, '_pool2User')} autoComplete="new_pool2User"/>
                                </div>
                                <div className="form-group col-6 pl-2">
                                    <label>Pass</label>
                                    <input className="form-control" type="text" value={this.props._pool2Pass}
                                    onChange={this.changeState.bind(this, '_pool2Pass')} autoComplete="new_pool2Pass"/>
                                </div>

                                <div className="divider"><span>Pool 3</span></div>
                                <div className="form-group col-12">
                                    <label>Url</label>
                                    <input className="form-control" type="text" value={this.props._pool3Url}
                                    onChange={this.changeState.bind(this, '_pool3Url')} autoComplete="new_pool3Url"/>
                                </div>
                                <div className="form-group col-6 pr-2">
                                    <label>User</label>
                                    <input className="form-control" type="text" value={this.props._pool3User}
                                    onChange={this.changeState.bind(this, '_pool3User')} autoComplete="new_pool3User"/>
                                </div>
                                <div className="form-group col-6 pl-2">
                                    <label>Pass</label>
                                    <input className="form-control" type="text" value={this.props._pool3Pass}
                                    onChange={this.changeState.bind(this, '_pool3Pass')} autoComplete="new_pool3Pass"/>
                                </div>
                                
                                
                            </div>
                            
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="action btn btn-primary"
                                onClick={this.props.saveFunction}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}