import React from 'react';
import {switchApi, authCheck, getAsicTypes, getAsicType, addAsicType, editAsicType, delAsicType} from '../Api';
import Modal from '../components/Modal';
import ModalAsicType from '../components/ModalAsicType';
import $ from 'jquery';
import Toast from '../components/Toast';
import Filter from '../components/Filter';
import Sidebar from '../components/Sidebar';
import AsicTypeTable from '../components/AsicTypeTable';

export default class PageAsicTypes extends React.Component {

    state = {
        toasts: [],
        filter: {
            vendor: '',
        },
        asictypes: [],
        asictype: {},        
        _id: 0,
        _vendor: 1,
        _vendors: [
            {id: 1, name: 'Whatsminer'},
            {id: 3, name: 'Bitmain'},
        ],
        _model: '',
        
    }


    toast(title, content){
        let toasts = this.state.toasts;
        let i = toasts.push({
            title: title,
            content: content
        });
        this.setState({toasts},()=>{
            $('#toast_'+(i-1)).toast({delay: 5000});
            $('#toast_'+(i-1)).toast('show');
        });        
    }


    changeFilter(name, value){
        let filter = this.state.filter;
        filter[name] = value;
        
        this.setState({filter},()=>{
            this.apiAuthCheck();
        });
    }


    componentDidMount(){
        this.isMount = true;
        this.apiAuthCheck();
    }


    componentWillUnmount(){
        this.isMount = false;
    }


    changeState(state, value){
        if (this.isMount) this.setState({[state]: value});
    }


    apiAuthCheck(){
        authCheck()
        .then((data)=>{
            if ((data.status === 200)&&(data.data.user)){
                if (this.isMount) this.setState({user: data.data.user});
                this.apiGetAsicTypes();
            }else{
                console.log(data);
                this.props.history.push('/');
            }          
        })
        .catch((err)=>{
            console.log(err);
            if (err.code === 'ECONNABORTED') {
                switchApi();
                this.apiAuthCheck();
            }else{
                this.props.history.push('/');
            }
        });
    }


    apiGetAsicTypes(){
        getAsicTypes('?vendor='+this.state.filter.vendor)
        .then((data)=>{
            if ((data.status === 200)&&(data.data.asictypes)){
                if (this.isMount) this.setState({asictypes: data.data.asictypes});
                
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }
    

    modalAddAsicType(){
        this.setState({
            _vendor: 1,
            _model: '',
            
        }, ()=>{
            $('#modalAddAsicType').modal('show');
        });
    }


    apiAddAsicType(){
        addAsicType({
            asicVendorId: this.state._vendor,
            model: this.state._model,
            
        })
        .then((data)=>{
            if ((data.status === 200)&&(!data.data.error)){
                $('#modalAddAsicType').modal('hide');
                this.toast('Success', 'Saved');
                this.apiGetAsicTypes();
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    modalEditAsicType(i){
        let _id = this.state.asictypes[i].id;
        getAsicType(_id)
        .then((data)=>{
            if ((data.status === 200)&&(data.data.asictype)){
                if (this.isMount) this.setState({
                    _id,
                    _vendor: (data.data.asictype.asicVendor.id) ? data.data.asictype.asicVendor.id : '',
                    _model: (data.data.asictype.model) ? data.data.asictype.model : '',
                    
                }, ()=>{
                    $('#modalEditAsicType').modal('show');
                });
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    apiEditAsicType(){
        editAsicType(this.state._id, {
            asicVendorId: this.state._vendor,
            model: this.state._model,
            
        })
        .then((data)=>{
            if ((data.status === 200)&&(!data.data.error)){
                $('#modalEditAsicType').modal('hide');
                this.toast('Success', 'Saved');
                this.apiGetAsicTypes();
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    modalDelAsicType(i){
        let _id = this.state.asictypes[i].id;
        this.setState({
            _id
        }, ()=>{
            $('#modalDelAsicType').modal('show');
        });
    }


    apiDelAsicType(){
        delAsicType(this.state._id)
        .then((data)=>{
            if ((data.status === 200)&&(!data.data.error)){
                $('#modalDelAsicType').modal('hide');
                this.toast('Success', 'Deleted');
                this.apiGetAsicTypes();
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    render() {
        return (
            (!this.state.user)
            ?<div className="app"></div>
            :<div className="app">
                <Sidebar history={this.props.history} user={this.state.user} customer={this.state.customer} customerId={this.state.customerId}/>
                <div className="app-content">
                    <div className="page page-asictypes">
                        <div className="toasts">
                            {(this.state.toasts) &&
                                this.state.toasts.map((toast, i)=>{
                                    return <Toast key={i} id={'toast_'+i} title={toast.title} content={toast.content} />
                                })
                            }
                        </div>
                        <div className="row">
                            <div className="col">
                                <h1 className="page-title">Asics Types                                
                                </h1>
                            </div>
                            <div className="col text-right">
                                {(this.state.user.userType.permissions.find((el) => el.id === 23)) &&
                                    <button className="btn btn-primary"
                                    onClick={this.modalAddAsicType.bind(this)}
                                    >New Asic Type</button>
                                }
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-lg-12">
                                <Filter 
                                    filter={this.state.filter}
                                    changeFilter={this.changeFilter.bind(this)}
                                    vendors={this.state.vendors}
                                />
                            </div>                                                  
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <AsicTypeTable 
                                    user={this.state.user}
                                    asictypes={this.state.asictypes}
                                    editFunction={this.modalEditAsicType.bind(this)}
                                    deleteFunction={this.modalDelAsicType.bind(this)}                                    
                                />
                            </div>
                        </div>                
                    </div>
                </div>
                <ModalAsicType
                    id="modalAddAsicType"
                    title="Add Asic Type"
                    changeState={this.changeState.bind(this)}
                    saveFunction={this.apiAddAsicType.bind(this)}
                    _vendor={this.state._vendor}
                    _vendors={this.state._vendors}
                    _model={this.state._model}
                    
                />
                <ModalAsicType
                    id="modalEditAsicType"
                    title="Edit Asic Type"
                    changeState={this.changeState.bind(this)}
                    saveFunction={this.apiEditAsicType.bind(this)}
                    _vendor={this.state._vendor}
                    _vendors={this.state._vendors}
                    _model={this.state._model}
                    
                />
                <Modal
                    id="modalDelAsicType"
                    title="Delete Asic Type"
                    content={'Do you really want to delete asic type #' + this.state._id + '?'}
                    confirmText="Delete"
                    confirmFunction={this.apiDelAsicType.bind(this)}
                />
            </div>        
        );
    }
}