import React from 'react';
import {switchApi, authCheck, getCustomers, getResellers, getManagers, getUserTypes, getUsers, getUser, editUser, delUser, resetPass } from '../Api';
import Modal from '../components/Modal';
import ModalPass from '../components/ModalPass';
import ModalUser from '../components/ModalUser';
import $ from 'jquery';
import Toast from '../components/Toast';
import Sidebar from '../components/Sidebar';
import UserTable from '../components/UserTable';

export default class PageAccounts extends React.Component {

    state = {
        toasts: [],
        filter: {
            customerId: '',
        },
        usertypes: [],
        users: [],
        userd: {},
        _id: 0,
        _email: '',
        _firstName: '',
        _lastName: '',
        _password: '',
        _password2: '',
        _userTypeId: 0,
        _customerIds: [],
        _status: '',
        statuses: ['pending', 'active', 'inactive'],
    }

    toast(title, content){
        let toasts = this.state.toasts;
        let i = toasts.push({
            title: title,
            content: content
        });
        this.setState({toasts},()=>{
            $('#toast_'+(i-1)).toast({delay: 5000});
            $('#toast_'+(i-1)).toast('show');
        });        
    }


    changeFilter(name, value){
        let filter = this.state.filter;
        filter[name] = value;
        
        this.setState({filter},()=>{
            this.apiAuthCheck();
        });
    }


    componentDidMount(){
        this.isMount = true;
        this.apiAuthCheck();
    }

    componentWillUnmount(){
        this.isMount = false;
    }

    changeState(state, value){
        if (this.isMount) this.setState({[state]: value});
    }

    
    apiAuthCheck(){
        authCheck()
        .then((data)=>{
            if ((data.status === 200)&&(data.data.user)){
                if (this.isMount) this.setState({user: data.data.user});
                this.apiGetManagers();
                this.apiGetResellers();
                this.apiGetCustomers();
                this.apiGetUserTypes();
                this.apiGetUsers();                
            }else{
                console.log(data);
                this.props.history.push('/');
            }          
        })
        .catch((err)=>{
            console.log(err);
            if (err.code === 'ECONNABORTED') {
                switchApi();
                this.apiAuthCheck();
            }else{
                this.props.history.push('/');
            }
        });
    }


    apiGetCustomers(){
        getCustomers()
        .then((data)=>{
            if ((data.status === 200)&&(data.data.customers)){
                if (this.isMount) this.setState({customers: data.data.customers});
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }

    apiGetResellers(){
        getResellers()
        .then((data)=>{
            if ((data.status === 200)&&(data.data.resellers)){
                if (this.isMount) this.setState({resellers: data.data.resellers});
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }

    apiGetManagers(){
        getManagers()
        .then((data)=>{
            if ((data.status === 200)&&(data.data.managers)){
                if (this.isMount) this.setState({managers: data.data.managers});
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    apiGetUserTypes(){
        getUserTypes()
        .then((data)=>{
            if ((data.status === 200)&&(data.data.usertypes)){
                if (this.isMount) this.setState({usertypes: data.data.usertypes});
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    apiGetUsers(){
        getUsers('?customerId='+this.state.filter.customerId)
        .then((data)=>{
            if ((data.status === 200)&&(data.data.users)){
                if (this.isMount) this.setState({users: data.data.users});
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    modalEditUser(i){
        let _id = this.state.users[i].id;
        getUser(_id)
        .then((data)=>{
            if ((data.status === 200)&&(!data.data.error)){
                let _managerIds = [];
                for (let managerId of data.data.user.managers){
                    _managerIds.push(managerId.id);
                }
                let _resellerIds = [];
                for (let resellerId of data.data.user.resellers){
                    _resellerIds.push(resellerId.id);
                }
                let _customerIds = [];
                for (let customerId of data.data.user.customers){
                    _customerIds.push(customerId.id);
                }
                if (this.isMount) this.setState({
                    _id,
                    _email: (data.data.user.email) ? data.data.user.email : '',
                    _firstName: (data.data.user.firstName) ? data.data.user.firstName : '',
                    _lastName: (data.data.user.lastName) ? data.data.user.lastName : '',
                    _status: (data.data.user.status) ? data.data.user.status : '',
                    _managerIds,
                    _resellerIds,
                    _customerIds,
                    _userType: (data.data.user.userType.name) ? data.data.user.userType.name : '',
                    _scope: (data.data.user.userType.scope) ? data.data.user.userType.scope : '',
                }, ()=>{
                    $('#modalEditUser').modal('show');
                });
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    apiEditUser(){
        editUser(
            this.state._id,
            {
                email: this.state._email,
                firstName: this.state._firstName,
                lastName: this.state._lastName,
                managerIds: this.state._managerIds,
                resellerIds: this.state._resellerIds,
                customerIds: this.state._customerIds,
                status: this.state._status,
            }
        )
        .then((data)=>{
            if ((data.status === 200)&&(!data.data.error)){
                $('#modalEditUser').modal('hide');
                this.toast('Success', 'Account saved');
                this.apiGetUsers();
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    modalDelUser(i){
        let _id = this.state.users[i].id;
        if (this.isMount) this.setState({
            _id,
        }, ()=>{
            $('#modalDelUser').modal('show');
        });
    }

    apiDelUser(){
        delUser(this.state._id)
        .then((data)=>{
            if ((data.status === 200)&&(!data.data.error)){
                $('#modalDelUser').modal('hide');
                this.toast('Success', 'Deleted');
                this.apiGetUsers();
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    modalResetPass(i){
        let _id = this.state.users[i].id;
        if (this.isMount) this.setState({
            _id,
            _password: '',
            _password2: '',            
        }, ()=>{
            $('#modalResetPass').modal('show');
        });
    }

    apiResetPass(){
        resetPass(
            this.state._id,
            {
                password: this.state._password,
                password2: this.state._password2,                
            }
        )
        .then((data)=>{
            if ((data.status === 200)&&(!data.data.error)){
                $('#modalResetPass').modal('hide');
                this.toast('Success', 'Password Reset');
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    render() {
        return (
            (!this.state.user)
            ?<div className="app"></div>
            :<div className="app">
                <Sidebar history={this.props.history} user={this.state.user} customer={this.state.customer} customerId={this.state.customerId}/>
                <div className="app-content">
                    <div className="page page-accounts">
                        <div className="toasts">
                            {(this.state.toasts) &&
                                this.state.toasts.map((toast, i)=>{
                                    return <Toast key={i} id={'toast_'+i} title={toast.title} content={toast.content} />
                                })
                            }
                        </div>
                        <div className="row">
                            <div className="col">
                                <h1 className="page-title">Accounts                                
                                </h1>
                            </div>
                            <div className="col text-right">                                
                            </div>
                        </div>   
                        <div className="row">
                            <div className="col-lg-12">
                                <UserTable 
                                    users={this.state.users} 
                                    editFunction={this.modalEditUser.bind(this)}
                                    resetPassFunction={this.modalResetPass.bind(this)}
                                    delFunction={this.modalDelUser.bind(this)}
                                />
                            </div>
                        </div>                
                    </div>
                </div>
                <ModalUser 
                    id="modalEditUser"
                    title="Edit Account"
                    changeState={this.changeState.bind(this)}
                    saveFunction={this.apiEditUser.bind(this)}
                    usertypes={this.state.usertypes}
                    customers={this.state.customers}
                    resellers={this.state.resellers}
                    managers={this.state.managers}
                    _email={this.state._email}
                    _firstName={this.state._firstName}
                    _lastName={this.state._lastName}
                    _userTypeId={this.state._userTypeId}
                    _customerIds={this.state._customerIds}
                    _resellerIds={this.state._resellerIds}
                    _managerIds={this.state._managerIds}
                    _status={this.state._status}
                    statuses={this.state.statuses}
                    _userType={this.state._userType}
                    _scope={this.state._scope}
                />
                <ModalPass
                    id="modalResetPass"
                    title="Reset Password"
                    changeState={this.changeState.bind(this)}
                    saveFunction={this.apiResetPass.bind(this)}
                    _password={this.state._password}
                    _password2={this.state._password2}
                />
                <Modal 
                    id="modalDelUser"
                    title="Delete Account"
                    content="Are you sure?"
                    confirmText="Delete"
                    confirmFunction={this.apiDelUser.bind(this)}
                />                
            </div>        
        );
    }
}