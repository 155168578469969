import React from 'react';
import {authLogout} from '../Api';


export default class Sidebar extends React.Component {

    state = {
        title: 'Asic.World',
        interval: 10000,
        status: 0,
        apiVersion: ''
    }

    componentDidMount(){
        this.isMount = true;
        // this.apiGetStatus();
        // this.intervalGetStatus = setInterval(this.apiGetStatus.bind(this), this.state.interval);             
    }

    componentWillUnmount(){
        this.isMount = false;
        // clearInterval(this.intervalGetStatus);
    }

    
    
    apiAuthLogout(){
        authLogout()
        .then(()=>{
            localStorage.removeItem('ASICWORLDJWT');
            this.props.history.push('/');            
        })
        .catch((err)=>{
            console.log(err);
            this.props.history.push('/');
        });
    }

    render() {
        const path = this.props.history.location.pathname;
        
        return (
            <div className="app-sidebar">
                <div className="sidebar">
                    <div className="panel logo">
                        <a href="/" title={this.state.title}
                            onClick={(e)=>{e.preventDefault(); this.props.history.push('/');}}>
                        </a>
                    </div>
                    <div className="panel menu-main">

                        <ul className="pt-3">
                            
                            <li className={(path === '/overview') ? "active" : ""}>
                                <a className="overview" href={'/overview'} onClick={(e)=>{
                                    e.preventDefault(); 
                                    this.props.history.push('/overview');
                                    }}>Overview
                                </a>
                            </li>                            
                            
                            {(this.props.user.userType.permissions.find((el) => el.id === 58)) &&
                                <li className="divider"><span>Users</span></li>
                            }

                            {(this.props.user.userType.permissions.find((el) => el.id === 54)) &&
                                <li className={(path === '/accounts') ? "active" : ""}>
                                    <a className="accounts" href={'/accounts'} onClick={(e)=>{
                                        e.preventDefault(); 
                                        this.props.history.push('/accounts');
                                        }}>Accounts
                                    </a>
                                </li>
                            }

                            {(this.props.user.userType.permissions.find((el) => el.id === 60)) &&
                                <li className={(path === '/managers') ? "active" : ""}>
                                    <a className="managers" href={'/managers'} onClick={(e)=>{
                                        e.preventDefault(); 
                                        this.props.history.push('/managers');
                                        }}>Managers
                                    </a>
                                </li>
                            }
                            
                            {(this.props.user.userType.permissions.find((el) => el.id === 61)) &&
                                <li className={(path === '/resellers') ? "active" : ""}>
                                    <a className="resellers" href={'/resellers'} onClick={(e)=>{
                                        e.preventDefault(); 
                                        this.props.history.push('/resellers');
                                        }}>Resellers
                                    </a>
                                </li>
                            }
                            
                            {(this.props.user.userType.permissions.find((el) => el.id === 58)) &&
                                <li className={(path === '/customers') ? "active" : ""}>
                                    <a className="customers" href={'/customers'} onClick={(e)=>{
                                        e.preventDefault(); 
                                        this.props.history.push('/customers');
                                        }}>Customers
                                    </a>
                                </li>
                            }
                    
                            
                            {(this.props.user.userType.permissions.find((el) => el.id === 55)) &&
                                <li className="divider"><span>Firmwares</span></li>
                            }
                            

                            {(this.props.user.userType.permissions.find((el) => el.id === 55)) &&
                            <li className={(path === '/firmwares') ? "active" : ""}>
                                <a className="firmwares" href={'/firmwares'} onClick={(e)=>{
                                    e.preventDefault(); 
                                    this.props.history.push('/firmwares');
                                    }}>Firmware
                                </a>
                            </li>
                            }

                            {(this.props.user.userType.permissions.find((el) => el.id === 56)) &&
                            <li className={(path === '/configs') ? "active" : ""}>
                                <a className="configs" href={'/configs'} onClick={(e)=>{
                                    e.preventDefault(); 
                                    this.props.history.push('/configs');
                                    }}>Configs
                                </a>
                            </li>
                            }

                            {(this.props.user.userType.permissions.find((el) => el.id === 71)) && 
                            <li className={(path === '/poolsets') ? "active" : ""}>
                                <a className="configs" href={'/poolsets'} onClick={(e)=>{
                                    e.preventDefault(); 
                                    this.props.history.push('/poolsets');
                                    }}>Pool Sets
                                </a>
                            </li>
                            }

                            {(this.props.user.userType.permissions.find((el) => el.id === 57)) &&
                                <li className="divider"><span>Asics</span></li>
                            }

                            {(this.props.user.userType.permissions.find((el) => el.id === 57)) &&
                            <li className={(path === '/asics') ? "active" : ""}>
                                <a className="asics" href={'/asics'} onClick={(e)=>{
                                    e.preventDefault(); 
                                    this.props.history.push('/asics');
                                    }}>Asics
                                </a>
                            </li>
                            }

                            {(this.props.user.userType.permissions.find((el) => el.id === 62)) &&
                            <li className={(path === '/stats') ? "active" : ""}>
                                <a className="stats" href={'/stats'} onClick={(e)=>{
                                    e.preventDefault(); 
                                    this.props.history.push('/stats');
                                    }}>Asic Stats
                                </a>
                            </li>
                            }

                            {(this.props.user.userType.permissions.find((el) => el.id === 59)) &&
                            <li className={(path === '/asictypes') ? "active" : ""}>
                                <a className="asic-types" href={'/asictypes'} onClick={(e)=>{
                                    e.preventDefault(); 
                                    this.props.history.push('/asictypes');
                                    }}>Asic Types
                                </a>
                            </li>
                            }
                                                
                        </ul>
                    </div>                
                    <div className="panel menu-actions">
                        <ul className="status">
                            <li>
                                {(this.props.customer) &&
                                    <div>
                                        <h5>{this.props.customer.company}</h5>
                                        <p>{this.props.customer.firstName} {this.props.customer.lastName}</p>
                                    </div>
                                }
                            </li> 
                            <li>
                                {(this.props.user) &&
                                    <div>
                                        <p>Logged as {this.props.user.userType.scope}:<br/>
                                            <b>{this.props.user.firstName} {this.props.user.lastName}</b> <br/>
                                            {this.props.user.email}
                                        </p>
                                    </div>
                                }
                            </li>                        
                        </ul>                                    
                        <ul className="actions">
                            <li>
                                <button className="btn btn-light"
                                onClick={this.apiAuthLogout.bind(this)}>
                                    Logout
                                </button>
                            </li>
                        </ul>
                    </div>
                    
                </div>
            </div>
        );
    }
}