import React from 'react';

export default class CustomerTable extends React.Component {

    state = {
        
    }

    editCustomer(i){
        this.props.editFunction(i)
    }

    deleteCustomer(i){
        this.props.deleteFunction(i)
    }

    changeState(state, e){
        this.props.changeState(state, e.target.value);
    }

    render() {
        return (
            <div className="component customer_table">
                <div className="panel pl-0 pr-0 pt-0 pb-0">
                    <table className="bordered">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Company</th>
                                <th>Address</th>
                                <th>Description</th>
                                <th>Email</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Phone</th>
                                <th>Manager</th>
                                <th>Reseller</th>
                                <th className=""></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(this.props.customers) &&
                                this.props.customers.map((customer, i)=>{
                                    return <tr key={i}>
                                                <td>{customer.id}</td>
                                                <td>{customer.company}</td>
                                                <td>{customer.address}</td>
                                                <td>{customer.description}</td>
                                                <td>{customer.email}</td>
                                                <td>{customer.firstName}</td>
                                                <td>{customer.lastName}</td>
                                                <td>{customer.phone}</td>
                                                <td>{(customer.manager) ? customer.manager.company : ''}</td>
                                                <td>{(customer.reseller) ? customer.reseller.company : ''}</td>
                                                <td className="actions">
                                                    {(this.props.user.userType.permissions.find((el) => el.id === 12)) &&
                                                    <div className="dropdown">
                                                        <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">                                                            
                                                        </button>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <button className="dropdown-item"
                                                                onClick={this.editCustomer.bind(this, i)}
                                                            >Edit</button>
                                                            <button className="dropdown-item" href="#"
                                                                onClick={this.deleteCustomer.bind(this, i)}
                                                            >Delete</button>
                                                        </div>
                                                    </div>
                                                    }
                                                </td>
                                            </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}