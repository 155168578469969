import React from 'react';
import $ from 'jquery';
export default class ModalUser extends React.Component {


    changeState(state, e){
        this.props.changeState(state, e.target.value);
    }
   
    changeMulti(state, e){
        this.props.changeState(state, $("#" + this.props.id + " .multiselect."+state).val());
    }

    render() {

        return (
            <div id={this.props.id} className="modal fade" tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">{this.props.title}</h3>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6 pr-lg-2">
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label>Email</label>
                                            <input className="form-control" type="text" value={this.props._email}
                                            onChange={this.changeState.bind(this, '_email')} autoComplete="new-email"/>
                                        </div>
                                        <div className="form-group col-12">
                                            <label>First Name</label>
                                            <input className="form-control" type="text" value={this.props._firstName}
                                            onChange={this.changeState.bind(this, '_firstName')} autoComplete="new-firstName"/>
                                        </div>
                                        <div className="form-group col-12">
                                            <label>Last Name</label>
                                            <input className="form-control" type="text" value={this.props._lastName}
                                            onChange={this.changeState.bind(this, '_lastName')} autoComplete="new-lastName"/>
                                        </div>                                        
                                    </div>
                                </div>
                                <div className="col-lg-6 pl-lg-2">
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label>Account Type</label>
                                            <input className="form-control" type="text" defaultValue={this.props._userType} readOnly={true}/>
                                        </div>
                                        <div className="form-group col-12">
                                            <label>Scope</label>
                                            <input className="form-control" type="text" defaultValue={this.props._scope} readOnly={true}/>
                                        </div>
                                        <div className="form-group col-12">
                                            <label>Status</label>
                                            <select className="custom-select" value={this.props._status}
                                            onChange={this.changeState.bind(this, '_status')}>
                                                {(this.props.statuses)&&(this.props.statuses.map((status, i)=>{
                                                    return <option key={i} value={status}>{status}</option>
                                                }))}                                        
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>                                    
                            <div className="row">
                                <div className="divider"><span>Access Control</span></div>
                                <div className="form-group col-4">
                                    <label>Managers</label>
                                    <select className="custom-select multiselect _managerIds" value={this.props._managerIds} multiple={true} 
                                    disabled={(this.props._scope === 'admin')||(this.props._scope !== 'manager')}
                                    onChange={this.changeMulti.bind(this, '_managerIds')}>
                                        <option value={0}></option>
                                        {(this.props.managers)&&(this.props.managers.map((manager, i)=>{
                                            return <option key={i} value={manager.id}>{manager.company}</option>
                                        }))}                                        
                                    </select>
                                </div>
                                <div className="form-group col-4">
                                    <label>Resellers</label>
                                    <select className="custom-select multiselect _resellerIds" value={this.props._resellerIds} multiple={true} 
                                    disabled={((this.props._scope === 'admin')||(this.props._scope === 'customer'))}
                                    onChange={this.changeMulti.bind(this, '_resellerIds')}>
                                        <option value={0}></option>
                                        {(this.props.resellers)&&(this.props.resellers.map((reseller, i)=>{
                                            return <option key={i} value={reseller.id}>{reseller.company}</option>
                                        }))}                                        
                                    </select>
                                </div>
                                <div className="form-group col-4">
                                    <label>Customers</label>
                                    <select className="custom-select multiselect _customerIds" value={this.props._customerIds} multiple={true} 
                                    disabled={(this.props._scope === 'admin')}
                                    onChange={this.changeMulti.bind(this, '_customerIds')}>
                                        <option value={0}></option>
                                        {(this.props.customers)&&(this.props.customers.map((customer, i)=>{
                                            return <option key={i} value={customer.id}>{customer.company}</option>
                                        }))}                                        
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="action btn btn-primary"
                                onClick={this.props.saveFunction}
                                >Save</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}