import React from 'react';

export default class ModalFW extends React.Component {

    changeState(state, e){
        this.props.changeState(state, e.target.value);
    }

    render() {

        return (
            <div id={this.props.id} className="modal fade" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">{this.props.title}</h3>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                
                                <div className="form-group col-12">
                                    <label>UUID (auto generated)</label>
                                    <input className="form-control" type="text" defaultValue={this.props._uid} readOnly={true}/>
                                </div>

                                <div className="divider"><span>General Settings</span></div>
                                {(this.props.id === 'modalAddFirmware')
                                ?   <div className="form-group col-12">
                                        <label>Customer</label>
                                        <select className="custom-select" value={this.props._customerId}
                                        onChange={this.changeState.bind(this, '_customerId')}>
                                            <option value={0}></option>
                                            {(this.props.customers)&&(this.props.customers.map((customer, i)=>{
                                                return <option key={i} value={customer.id}>{customer.company}</option>
                                            }))}                                        
                                        </select>
                                    </div>
                                :   <div className="form-group col-12">
                                        <label>Customer</label>
                                        <select className="custom-select" defaultValue={this.props._customerId}>
                                            {(this.props.customers)&&(this.props.customers.map((customer, i)=>{
                                                if (this.props._customerId === customer.id)
                                                return <option key={i} value={customer.id}>{customer.company}</option>
                                            }))}                                        
                                        </select>
                                    </div>
                                }
                                {(this.props.id === 'modalAddFirmware')
                                ?   <div className="form-group col-12">
                                        <label>Asic Type</label>
                                        <select className="custom-select" value={this.props._asicTypeId}
                                        onChange={this.changeState.bind(this, '_asicTypeId')}>
                                            <option value={0}></option>
                                            {(this.props.asictypes)&&(this.props.asictypes.map((asictype, i)=>{
                                                return <option key={i} value={asictype.id}>{asictype.vendor} {asictype.model}</option>
                                            }))}                                        
                                        </select>
                                    </div>
                                :   <div className="form-group col-12">
                                        <label>Asic Type</label>
                                        <select className="custom-select" defaultValue={this.props._asicTypeId}>
                                            {(this.props.asictypes)&&(this.props.asictypes.map((asictype, i)=>{
                                                if (this.props._asicTypeId === asictype.id)
                                                return <option key={i} value={asictype.id}>{asictype.vendor} {asictype.model}</option>
                                            }))}                                        
                                        </select>
                                    </div>
                                }
                                
                                <div className="form-group col-12">
                                    <label>Asic Limit</label>
                                    <input className="form-control" type="test" value={this.props._maxAsics}
                                    onChange={this.changeState.bind(this, '_maxAsics')} autoComplete="new-maxAsics" 
                                    readOnly={(this.props.user.userType.scope !== 'admin')}/>
                                </div>
                                
                                <div className="form-group col-12">
                                    <label>IP Range</label>
                                    <input className="form-control" type="test" value={this.props._ipRange}
                                    onChange={this.changeState.bind(this, '_ipRange')} autoComplete="new-ipRange"/>
                                </div>

                                <div className="divider"><span>Fee Settings</span></div>
                                <div className="form-group col-4 pr-2">
                                    <label>Reseller Fee, %</label>
                                    <input className="form-control" type="test" value={this.props._feeRes}
                                    onChange={this.changeState.bind(this, '_feeRes')} autoComplete="new_feeRes"/>
                                </div>
                                <div className="form-group col-4 pl-2 pr-2">
                                    <label>Manager Fee, %</label>
                                    <input className="form-control" type="test" value={this.props._feeMan}
                                    onChange={this.changeState.bind(this, '_feeMan')} autoComplete="new_feeMan"/>
                                </div>                                
                                <div className="form-group col-4 pl-2">
                                    <label>Dev Fee, %</label>
                                    <input className="form-control" type="test" value={this.props._feeDev}
                                    onChange={this.changeState.bind(this, '_feeDev')} autoComplete="new_feeDev"/>
                                </div>

                            </div>                            
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="action btn btn-primary"
                                onClick={this.props.saveFunction}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}