import React from 'react';
import {switchApi, authCheck, getCustomers, getFirmwares, getConfigs, getPoolsets, getAsics, editAsic, getBP, jobRestartMiner, jobStopMiner, jobRebootSystem, jobUninstall, jobRSC, jobRSO, jobCancel, jobReinstall, jobFanAdjust } from '../Api';
import Modal from '../components/Modal';
import ModalAsic from '../components/ModalAsic';
import ModalFan from '../components/ModalFan';
import $ from 'jquery';
import Toast from '../components/Toast';
import Filter from '../components/Filter';
import Sidebar from '../components/Sidebar';
import AsicTable from '../components/AsicTable';


export default class PageAsics extends React.Component {

    state = {
        toasts: [],
        filter: {
            customerId: '',
            firmwareId: '',
            configId: '',
            active: '1'
        },
        activities: [{id:1, name:'Active only'}, {id:0, name:'Show All'}],
        interval: 60000,
        firmwares: [],
        configs: [],
        poolsets: [],
        asics: [],
        _id: 0,
        _configId: 0,
        _poolsetId: 0,
    }

    
    toast(title, content){
        let toasts = this.state.toasts;
        let i = toasts.push({
            title: title,
            content: content
        });
        this.setState({toasts},()=>{
            $('#toast_'+(i-1)).toast({delay: 5000});
            $('#toast_'+(i-1)).toast('show');
        });        
    }


    changeFilter(name, value){
        let filter = this.state.filter;
        filter[name] = value;
        
        if (name === 'customerId') { filter.firmwareId=''; filter.configId=''; }
        if (name === 'firmwareId') { filter.configId=''; }

        this.setState({filter},()=>{
            this.apiAuthCheck();
        });
    }


    componentDidMount(){
        this.isMount = true;
        this.apiAuthCheck();
        this.intervalGetAsics = setInterval(this.apiGetAsics.bind(this), this.state.interval);
    }


    componentWillUnmount(){
        this.isMount = false;
        clearInterval(this.intervalGetAsics);
    }


    changeState(state, value){
        if (this.isMount) this.setState({[state]: value});
    }


    apiAuthCheck(){
        authCheck()
        .then((data)=>{
            if ((data.status === 200)&&(data.data.user)){
                if (this.isMount) this.setState({user: data.data.user});
                this.apiGetCustomers();
                this.apiGetFirmwares();
                this.apiGetConfigs();
                this.apiGetPoolsets();
                this.apiGetAsics();
            }else{
                console.log(data);
                this.props.history.push('/');
            }          
        })
        .catch((err)=>{
            console.log(err);
            if (err.code === 'ECONNABORTED') {
                switchApi();
                this.apiAuthCheck();
            }else{
                this.props.history.push('/');
            }
        });
    }

    
    apiGetCustomers(){
        getCustomers()
        .then((data)=>{
            if ((data.status === 200)&&(data.data.customers)){
                if (this.isMount) this.setState({customers: data.data.customers});                
            }else{
                console.log(data);
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }

    
    apiGetFirmwares(){
        getFirmwares('?customerId='+this.state.filter.customerId)
        .then((data)=>{
            if ((data.status === 200)&&(data.data.firmwares)){
                if (this.isMount) this.setState({firmwares: data.data.firmwares});
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    apiGetConfigs(){
        getConfigs('?customerId='+this.state.filter.customerId+'&firmwareId='+this.state.filter.firmwareId)
        .then((data)=>{
            if ((data.status === 200)&&(data.data.configs)){
                if (this.isMount) this.setState({configs: data.data.configs});
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    apiGetPoolsets(){
        getPoolsets('?customerId='+this.state.filter.customerId)
        .then((data)=>{
            if ((data.status === 200)&&(data.data.poolsets)){
                if (this.isMount) this.setState({poolsets: data.data.poolsets});
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    apiGetAsics(){
        getAsics('?customerId='+this.state.filter.customerId+'&firmwareId='+this.state.filter.firmwareId+'&configId='+this.state.filter.configId+'&active='+this.state.filter.active)
        .then((data)=>{
            if ((data.status === 200)&&(data.data.asics)){
                if (this.isMount) this.setState({asics: data.data.asics});
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    modalEditAsic(i){
        let _id = this.state.asics[i].id;
        let _configId = this.state.asics[i].config.id;
        let _poolsetId = this.state.asics[i].poolset ? this.state.asics[i].poolset.id : 0;
        let filter = '?firmwareId='+this.state.asics[i].firmware.id;
        getConfigs(filter)        
        .then((data)=>{
            if ((data.status === 200)&&(data.data.configs)){
                if (this.isMount) this.setState({
                    _id,
                    _configId,
                    _configs: data.data.configs
                }
                // , ()=>{
                //     $('#modalEditAsic').modal('show');
                // }
                );
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }
            return getPoolsets('?customerId='+this.state.asics[i].firmware.customer.id);
        })
        .then((data) => {
            if (data.status === 200){
                if (this.isMount) this.setState({
                    _poolsetId,
                    _poolsets: data.data.poolsets
                }, ()=>{
                    $('#modalEditAsic').modal('show');
                });
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });        
    }

    apiEditAsic(){
        editAsic(this.state._id, {
            configId: this.state._configId,
            poolsetId: this.state._poolsetId,
        })
        .then((data)=>{
            if ((data.status === 200)&&(!data.data.error)){
                $('#modalEditAsic').modal('hide');
                this.toast('Success', 'Saved');
                jobRestartMiner(this.state._id)
                .then((data)=>{
                    if ((data.status === 200)&&(!data.data.error)){
                        this.apiGetAsics();
                        this.toast('Success', data.data.result);
                    }else{
                        this.toast('Error', JSON.stringify(data.data.error));
                    }          
                })
                .catch((err)=>{
                    console.log(err);
                });
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }

    modalCancelJobs(i){
        let _id = this.state.asics[i].id;
        this.setState({
            _id,            
        }, ()=>{
            $('#modalCancelJobs').modal('show');
        });                
    }

    apiCancelJobs(){
        jobCancel(this.state._id)
        .then((data)=>{
            if ((data.status === 200)&&(!data.data.error)){
                $('#modalCancelJobs').modal('hide');
                this.apiGetAsics();
                this.toast('Success', data.data.result);
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }

    modalFanAdjust(i){
        let _id = this.state.asics[i].id;
        let _adjust_temp = this.state.asics[i].asicStats[0].fan_adjust_temp;
        let _target_temp = this.state.asics[i].asicStats[0].fan_target_temp;
        let _protect_temp = this.state.asics[i].asicStats[0].fan_protect_temp;
        this.setState({
            _id,
            _adjust_temp,
            _target_temp,
            _protect_temp
        }, ()=>{
            $('#modalFanAdjust').modal('show');
        });                
    }

    apiFanAdjust(){
        if (!this.state._adjust_temp || !this.state._protect_temp || !this.state._target_temp) {
            this.toast('Error', 'Cannot read temp data');
            return
        }
        jobFanAdjust(this.state._id, {
            adjust_temp: this.state._adjust_temp,
            target_temp: this.state._target_temp,
            protect_temp: this.state._protect_temp
        })
        .then((data)=>{
            if ((data.status === 200)&&(!data.data.error)){
                $('#modalFanAdjust').modal('hide');
                this.apiGetAsics();
                this.toast('Success', data.data.result);
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }

    modalRestartMiner(i){
        let _id = this.state.asics[i].id;
        this.setState({
            _id,            
        }, ()=>{
            $('#modalRestartMiner').modal('show');
        });                
    }


    apiRestartMiner(){
        jobRestartMiner(this.state._id)
        .then((data)=>{
            if ((data.status === 200)&&(!data.data.error)){
                $('#modalRestartMiner').modal('hide');
                this.apiGetAsics();
                this.toast('Success', data.data.result);
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    modalStopMiner(i){
        let _id = this.state.asics[i].id;
        this.setState({
            _id,            
        }, ()=>{
            $('#modalStopMiner').modal('show');
        });                
    }


    apiStopMiner(){
        jobStopMiner(this.state._id)
        .then((data)=>{
            if ((data.status === 200)&&(!data.data.error)){
                $('#modalStopMiner').modal('hide');
                this.apiGetAsics();
                this.toast('Success', data.data.result);
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    modalRebootAsic(i){
        let _id = this.state.asics[i].id;
        this.setState({
            _id,            
        }, ()=>{
            $('#modalRebootAsic').modal('show');
        });                
    }


    apiRebootAsic(){
        jobRebootSystem(this.state._id)
        .then((data)=>{
            if ((data.status === 200)&&(!data.data.error)){
                $('#modalRebootAsic').modal('hide');
                this.apiGetAsics();
                this.toast('Success', data.data.result);
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }

    modalUninstallAsic(i){
        let _id = this.state.asics[i].id;
        this.setState({
            _id,            
        }, ()=>{
            $('#modalUninstallAsic').modal('show');
        });                
    }

    apiUninstallAsic(){
        jobUninstall(this.state._id)
        .then((data)=>{
            if ((data.status === 200)&&(!data.data.error)){
                $('#modalUninstallAsic').modal('hide');
                this.apiGetAsics();
                this.toast('Success', data.data.result);
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    modalRSO(i){
        let _id = this.state.asics[i].id;
        let _port = 0;
        getBP()
        .then((data)=>{
            if ((data.status === 200)&&(data.data.ports)){
                for (let p = 65535; p > 60000; p--){
                    let x = data.data.ports.find(({port}) => parseInt(port) === p);
                    if (!x) {
                        _port = p;
                        break;
                    }                    
                }
            }else{
                _port = 65535;
            }          
            
            this.setState({
                _id,
                _port
            }, ()=>{
                $('#modalRSO').modal('show');
            });                

        })       
    }


    apiRSO(){
        jobRSO(this.state._id, this.state._port)
        .then((data)=>{

            if ((data.status === 200)&&(!data.data.error)){
                this.toast('Success', data.data.result);

                editAsic(this.state._id, {
                    port: this.state._port
                })
                .then((data)=>{
                    if ((data.status === 200)&&(!data.data.error)){
                        $('#modalRSO').modal('hide');
                        this.apiGetAsics();
                        this.toast('Success', 'RSO on '+this.state._port);
                    }else{
                        this.toast('Error', JSON.stringify(data.data.error));
                    }          
                })
                .catch((err)=>{
                    console.log(err);
                });

            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }
            
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    modalRSC(i){
        let _id = this.state.asics[i].id;
        this.setState({
            _id,            
        }, ()=>{
            $('#modalRSC').modal('show');
        });                
    }


    apiRSC(){
        jobRSC(this.state._id)
        .then((data)=>{
            if ((data.status === 200)&&(!data.data.error)){
                $('#modalRSC').modal('hide');
                this.apiGetAsics();
                this.toast('Success', data.data.result);
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    modalReinstall(i){
        let _id = this.state.asics[i].id;
        this.setState({
            _id,            
        }, ()=>{
            $('#modalReinstall').modal('show');
        });                
    }

    apiReinstall(){
        jobReinstall(this.state._id)
        .then((data)=>{
            if ((data.status === 200)&&(!data.data.error)){
                $('#modalReinstall').modal('hide');
                this.apiGetAsics();
                this.toast('Success', data.data.result);
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    render() {
        return (
            (!this.state.user)
            ?<div className="app"></div>
            :<div className="app">
                <Sidebar history={this.props.history} user={this.state.user} customer={this.state.customer} customerId={this.state.customerId}/>
                <div className="app-content">
                    <div className="page page-asics">
                        <div className="toasts">
                            {(this.state.toasts) &&
                                this.state.toasts.map((toast, i)=>{
                                    return <Toast key={i} id={'toast_'+i} title={toast.title} content={toast.content} />
                                })
                            }
                        </div>
                        <div className="row">
                            <div className="col">
                                <h1 className="page-title">Asics                                
                                </h1>
                            </div>
                            <div className="col text-right">                                
                            </div>
                        </div>         
                        <div className="row mt-4">
                            <div className="col-lg-12">
                                <Filter 
                                    filter={this.state.filter}
                                    changeFilter={this.changeFilter.bind(this)}
                                    customers={(this.state.user.userType.permissions.find((el) => el.id === 58)) && this.state.customers}
                                    firmwares={(this.state.user.userType.permissions.find((el) => el.id === 55)) && this.state.firmwares}
                                    configs={(this.state.user.userType.permissions.find((el) => el.id === 56)) && this.state.configs}
                                    activities={this.state.activities}
                                />
                            </div>                                                  
                        </div>    
                        <div className="row">
                            <div className="col-lg-12">
                                <AsicTable 
                                    user={this.state.user}
                                    asics={this.state.asics}
                                    editFunction={this.modalEditAsic.bind(this)}
                                    restartFunction={this.modalRestartMiner.bind(this)}
                                    stopFunction={this.modalStopMiner.bind(this)}
                                    rebootFunction={this.modalRebootAsic.bind(this)}
                                    uninstallFunction={this.modalUninstallAsic.bind(this)}
                                    rsoFunction={this.modalRSO.bind(this)}
                                    rscFunction={this.modalRSC.bind(this)}
                                    cancelFunction={this.modalCancelJobs.bind(this)}
                                    reinstallFunction={this.modalReinstall.bind(this)}
                                    fanAdjustFunction={this.modalFanAdjust.bind(this)}
                                />
                            </div>
                        </div>                
                    </div>
                </div>
                <ModalAsic
                    id="modalEditAsic"
                    title="Set Config &amp; Pools"
                    changeState={this.changeState.bind(this)}
                    saveFunction={this.apiEditAsic.bind(this)}
                    _configs={this.state._configs}
                    _configId={this.state._configId}
                    _poolsets={this.state._poolsets}
                    _poolsetId={this.state._poolsetId}
                />
                <Modal 
                    id="modalRestartMiner"
                    title="Restart Miner"
                    content="Are you sure?"
                    confirmText="Restart"
                    confirmFunction={this.apiRestartMiner.bind(this)}
                />
                <Modal 
                    id="modalStopMiner"
                    title="Stop Miner"
                    content="Are you sure?"
                    confirmText="Stop"
                    confirmFunction={this.apiStopMiner.bind(this)}
                />
                <Modal 
                    id="modalRebootAsic"
                    title="Reboot Asic"
                    content="Are you sure?"
                    confirmText="Reboot"
                    confirmFunction={this.apiRebootAsic.bind(this)}
                />
                <Modal 
                    id="modalUninstallAsic"
                    title="Reset to default FW"
                    content="Are you sure to reset to default FW?"
                    confirmText="Reset"
                    confirmFunction={this.apiUninstallAsic.bind(this)}
                />
                <Modal
                    id="modalRSO"
                    title="RSO"
                    content="Are you sure?"
                    confirmText="Go"
                    confirmFunction={this.apiRSO.bind(this)}
                />
                <Modal 
                    id="modalRSC"
                    title="RSC"
                    content="Are you sure?"
                    confirmText="Go"
                    confirmFunction={this.apiRSC.bind(this)}
                />                
                <Modal 
                    id="modalCancelJobs"
                    title="Cancel Asic Jobs"
                    content="Are you sure?"
                    confirmText="Go"
                    confirmFunction={this.apiCancelJobs.bind(this)}
                />
                <Modal 
                    id="modalReinstall"
                    title="Reinstall asic"
                    content="Are you sure?"
                    confirmText="Go"
                    confirmFunction={this.apiReinstall.bind(this)}
                />
                <ModalFan
                    id="modalFanAdjust"
                    title="Adjust asics fan"
                    changeState={this.changeState.bind(this)}
                    saveFunction={this.apiFanAdjust.bind(this)}
                    _adjust_temp={this.state._adjust_temp}
                />
            </div>        
        );
    }
}