import React from 'react';

export default class PoolsetTable extends React.Component {

    state = {
        
    }

    editPoolset(i){
        this.props.editFunction(i)
    }

    deletePoolset(i){
        this.props.deleteFunction(i)
    }

    changeState(state, e){
        this.props.changeState(state, e.target.value);
    }

    render() {
        return (
            <div className="component poolset-table">
                <div className="panel pl-0 pr-0 pt-0 pb-0">
                    <table className="bordered">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Customer</th>
                                <th>Name</th>
                                <th>Pool1Url</th>
                                <th>Pool2Url</th>
                                <th>Pool3Url</th>                                
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(this.props.poolsets) &&
                                this.props.poolsets.map((poolset, i)=>{
                                    return <tr key={i}>
                                                <td>{poolset.id}</td>
                                                <td>{poolset.customer.company}</td>
                                                <td>{poolset.name}</td>
                                                <td>{poolset.pool1Url}</td>
                                                <td>{poolset.pool2Url}</td>
                                                <td>{poolset.pool3Url}</td>
                                                <td className="actions">
                                                    {(this.props.user.userType.permissions.find((el) => el.id === 69)) &&
                                                    <div className="dropdown">
                                                        <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">                                                            
                                                        </button>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <button className="dropdown-item"
                                                                onClick={this.editPoolset.bind(this, i)}
                                                            >Edit</button>
                                                            <button className="dropdown-item" href="#"
                                                                onClick={this.deletePoolset.bind(this, i)}
                                                            >Delete</button>
                                                        </div>
                                                    </div>
                                                    }
                                                </td>                                                
                                            </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}