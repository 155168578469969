import React from 'react';

export default class Filter extends React.Component {

    state ={
        
    }

    changeFilter(name, e){
        this.props.changeFilter(name, e.target.value);
    }

    
    render() {
        return (
            <div className="custom-filter row">

                
                {((this.props.customers)&&(this.props.filter))&&
                    <div className="form-group col-md-2 m-2">
                        <label>Customer</label>
                        <select className="custom-select" value={this.props.filter.customerId}
                            onChange={this.changeFilter.bind(this, 'customerId')}>
                            <option value=''></option>
                            {this.props.customers.map((customer, i)=>{
                                return <option key={i} value={customer.id}>{customer.company}</option>
                            })}
                        </select>
                    </div>
                }

                
                {((this.props.asictypes)&&(this.props.filter))&&
                    <div className="form-group col-md-2 m-2">
                        <label>Asic Type</label>
                        <select className="custom-select" value={this.props.filter.asicTypeId}
                            onChange={this.changeFilter.bind(this, 'asicTypeId')}>
                            <option value=''></option>
                            {this.props.asictypes.map((asictype, i)=>{
                                return <option key={i} value={asictype.id}>{asictype.vendor} {asictype.model}</option>
                            })}
                        </select>
                    </div>
                }


                {((this.props.firmwares)&&(this.props.filter))&&
                    <div className="form-group col-md-2 m-2">
                        <label>Firmware</label>
                        <select className="custom-select" value={this.props.filter.firmwareId}
                            onChange={this.changeFilter.bind(this, 'firmwareId')}>
                            <option value=''></option>
                            {this.props.firmwares.map((firmware, i)=>{
                                return <option key={i} value={firmware.id}>{firmware.customer.company} {firmware.asicType.model}</option>
                            })}
                        </select>
                    </div>
                }
                

                {((this.props.configs)&&(this.props.filter))&&
                    <div className="form-group col-md-2 m-2">
                        <label>Config</label>
                        <select className="custom-select" value={this.props.filter.configId}
                            onChange={this.changeFilter.bind(this, 'configId')}>
                            <option value=''></option>
                            {this.props.configs.map((config, i)=>{
                                return <option key={i} value={config.id}>{config.name}</option>
                            })}
                        </select>
                    </div>
                }


                {((this.props.asics)&&(this.props.filter))&&
                    <div className="form-group col-md-2 m-2">
                        <label>Asic</label>
                        <select className="custom-select" value={this.props.filter.asicId}
                            onChange={this.changeFilter.bind(this, 'asicId')}>
                            <option value=''></option>
                            {this.props.asics.map((asic, i)=>{
                                return <option key={i} value={asic.id}>{asic.ip}/{asic.pip}</option>
                            })}
                        </select>
                    </div>
                }


                {((this.props.users)&&(this.props.filter))&&
                    <div className="form-group col-md-2 m-2">
                        <label>User</label>
                        <select className="custom-select" value={this.props.filter.userId}
                            onChange={this.changeFilter.bind(this, 'userId')}>
                            <option value=''></option>
                            {this.props.users.map((user, i)=>{
                                return <option key={i} value={user.id}>{user.email}</option>
                            })}
                        </select>
                    </div>
                }


                {((this.props.activities)&&(this.props.filter))&&
                    <div className="form-group col-md-2 m-2">
                        <label>Show</label>
                        <select className="custom-select" value={this.props.filter.active}
                            onChange={this.changeFilter.bind(this, 'active')}>
                            {this.props.activities.map((activity, i)=>{
                                return <option key={i} value={activity.id}>{activity.name}</option>
                            })}
                        </select>
                    </div>
                }


            </div>      
        );
    }
}