import React from 'react';

export default class ManagerTable extends React.Component {

    state = {
        
    }

    editManager(i){
        this.props.editFunction(i)
    }

    deleteManager(i){
        this.props.deleteFunction(i)
    }

    changeState(state, e){
        this.props.changeState(state, e.target.value);
    }

    render() {
        return (
            <div className="component manager_table">
                <div className="panel pl-0 pr-0 pt-0 pb-0">
                    <table className="bordered">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Company</th>
                                <th>Address</th>
                                <th>Description</th>
                                <th>Email</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Phone</th>
                                <th className=""></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(this.props.managers) &&
                                this.props.managers.map((manager, i)=>{
                                    return <tr key={i}>
                                                <td>{manager.id}</td>
                                                <td>{manager.company}</td>
                                                <td>{manager.address}</td>
                                                <td>{manager.description}</td>
                                                <td>{manager.email}</td>
                                                <td>{manager.firstName}</td>
                                                <td>{manager.lastName}</td>
                                                <td>{manager.phone}</td>
                                                <td className="actions">
                                                    {(this.props.user.userType.permissions.find((el) => el.id === 47)) &&
                                                    <div className="dropdown">
                                                        <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">                                                            
                                                        </button>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <button className="dropdown-item"
                                                                onClick={this.editManager.bind(this, i)}
                                                            >Edit</button>
                                                            <button className="dropdown-item" href="#"
                                                                onClick={this.deleteManager.bind(this, i)}
                                                            >Delete</button>
                                                        </div>
                                                    </div>
                                                    }
                                                </td>
                                            </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}