import React from 'react';
import {switchApi, authCheck, authRegister } from '../Api';
import $ from 'jquery';
import Toast from '../components/Toast';

export default class PageRegister extends React.Component {

    state = {
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        company: '',
        phone: '',
        toasts: [],
    }

    toast(title, content){
        let toasts = this.state.toasts;
        let i = toasts.push({
            title: title,
            content: content
        });
        this.setState({toasts},()=>{
            $('#toast_'+(i-1)).toast({delay: 5000});
            $('#toast_'+(i-1)).toast('show');
        });        
    }

    componentDidMount(){
        // temp redirect to login
        this.props.history.push('/login');
        this.isMount = true;
        this.apiAuthCheck();
    }

    componentWillUnmount(){
        this.isMount = false;
    }

    changeState(state, e){
        if (this.isMount) this.setState({[state]: e.target.value});
    }

    apiAuthCheck(){
        authCheck()
        .then((data)=>{
            if (data.status === 200){
              if (data.data.user){
                this.props.history.push('/customers');
              }
            }else{
                console.log(data);
            }          
        })
        .catch((err)=>{
            console.log(err);
            if (err.code === 'ECONNABORTED') {
                switchApi();
                this.apiAuthCheck();
            }
        });
    }


    apiAuthRegister(){
        authRegister({
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            company: this.state.company,
            phone: this.state.phone,
            email: this.state.email,
            password: this.state.password
        })
        .then((data)=>{
            if ((data.status === 200) && (data.data.user)){
                this.toast('Success', 'Customer registered');
                setTimeout(()=>{
                    this.props.history.push('/');
                }, 5000);                
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            } 
        })
        .catch((err)=>{
            console.log(err);
            if (err.code === 'ECONNABORTED') {
                switchApi();
                this.apiAuthRegister();
            }
        });
    }

    render() {
        return (
            <div className="app">
                <div className="app-content">
                    <div className="page page-form page-register">
                        <div className="toasts">
                            {(this.state.toasts) &&
                                this.state.toasts.map((toast, i)=>{
                                    return <Toast key={i} id={'toast_'+i} title={toast.title} content={toast.content} />
                                })
                            }
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="logo mb-5"><a href="/" >&nbsp;</a></div>
                                <h1 className="page-title">Registration</h1>
                                <div className="form-group">
                                    <label>First Name</label>
                                    <input className="form-control" type="text" value={this.state.firstName}
                                    onChange={this.changeState.bind(this, 'firstName')} />
                                </div>
                                <div className="form-group">
                                    <label>Last Name</label>
                                    <input className="form-control" type="text" value={this.state.lastName}
                                    onChange={this.changeState.bind(this, 'lastName')} />
                                </div>
                                <div className="form-group">
                                    <label>Company</label>
                                    <input className="form-control" type="text" value={this.state.company}
                                    onChange={this.changeState.bind(this, 'company')} />
                                </div>
                                <div className="form-group">
                                    <label>Phone</label>
                                    <input className="form-control" type="text" value={this.state.phone}
                                    onChange={this.changeState.bind(this, 'phone')} />
                                </div>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input className="form-control" type="email" value={this.state.email}
                                    onChange={this.changeState.bind(this, 'email')} />
                                </div>
                                <div className="form-group">
                                    <label>Password</label>
                                    <input className="form-control" type="password" value={this.state.password}
                                    onChange={this.changeState.bind(this, 'password')} />
                                </div>
                                <div className="form-group page-form-actions mt-4">
                                    <a href="/login"
                                        onClick={(e)=>{e.preventDefault(); this.props.history.push('/login');}}>
                                        I have an account
                                    </a>
                                    <button className="btn btn-primary"
                                        onClick={this.apiAuthRegister.bind(this)}
                                    >Register</button>
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}