import React from 'react';
import 'popper.js';
import 'bootstrap';
import pkg from '../package.json';
import {Route, Switch} from "react-router-dom";

import PageLogin from './pages/PageLogin';
import PageRegister from './pages/PageRegister';
import PageForgot from './pages/PageForgot';

import PageCustomers from './pages/PageCustomers';
import PageManagers from './pages/PageManagers';
import PageResellers from './pages/PageResellers';
import PageOverview from './pages/PageOverview';
import PageFirmwares from './pages/PageFirmwares';
import PageConfigs from './pages/PageConfigs';
import PagePoolsets from './pages/PagePoolsets';
import PageAsics from './pages/PageAsics';
import PageAsicTypes from './pages/PageAsicTypes';
import PageAccounts from './pages/PageAccounts';
import PageStats from './pages/PageStats';


export default class App extends React.Component {
  
  state = {
    
  }

  render() {
    return (
      <div className="app-wrap" id="app-wrap">
        <div className="version">v{pkg.version}</div>
          <Switch>
            <Route exact path='/' component={PageLogin}/>
            <Route exact path='/login' component={PageLogin}/>
            <Route exact path='/register' component={PageRegister}/>
            <Route exact path='/forgot' component={PageForgot}/>

            <Route exact path='/overview' component={PageOverview}/>
            <Route exact path='/managers' component={PageManagers}/>
            <Route exact path='/resellers' component={PageResellers}/>
            <Route exact path='/customers' component={PageCustomers}/>
            <Route exact path='/firmwares' component={PageFirmwares}/>
            <Route exact path='/configs' component={PageConfigs}/>
            <Route exact path='/poolsets' component={PagePoolsets}/>
            <Route exact path='/accounts' component={PageAccounts}/>
            <Route exact path='/asics' component={PageAsics}/>
            <Route exact path='/asictypes' component={PageAsicTypes}/>
            <Route exact path='/stats' component={PageStats}/>

            <Route component={PageLogin} />
          </Switch>
        </div>      
    );
  }
}