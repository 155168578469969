import React from 'react';

export default class FirmwareTable extends React.Component {

    state = {
        
    }

    editFirmware(i){
        this.props.editFunction(i)
    }

    deleteFirmware(i){
        this.props.deleteFunction(i)
    }

    changeState(state, e){
        this.props.changeState(state, e.target.value);
    }

    render() {
        return (
            <div className="component firmware-table">
                <div className="panel pl-0 pr-0 pt-0 pb-0">
                    <table className="bordered">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Customer</th>
                                <th>Asic Type</th>
                                <th>UUID</th>
                                <th>Asic Limit</th>
                                <th>IP Range</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(this.props.firmwares) &&
                                this.props.firmwares.map((firmware, i)=>{
                                    return <tr key={i}>
                                                <td>{firmware.id}</td>
                                                <td>{firmware.customer.company}</td>
                                                <td>{firmware.asicType.model}</td>
                                                <td>{firmware.uid}</td>
                                                <td>{firmware.maxAsics}</td>
                                                <td>{firmware.ipRange}</td>
                                                <td className="actions">
                                                    {(this.props.user.userType.permissions.find((el) => el.id === 8)) &&
                                                    <div className="dropdown">
                                                        <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">                                                            
                                                        </button>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <button className="dropdown-item"
                                                                onClick={this.editFirmware.bind(this, i)}
                                                            >Edit</button>
                                                            <button className="dropdown-item" href="#"
                                                                onClick={this.deleteFirmware.bind(this, i)}
                                                            >Delete</button>
                                                        </div>
                                                    </div>
                                                    }
                                                </td>                                                
                                            </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}