import React from 'react';
import {switchApi, authCheck, getChartData, getCustomers, getConfigs, getFirmwares, getAsics} from '../Api';

import Sidebar from '../components/Sidebar';
import $ from 'jquery';
import Toast from '../components/Toast';
import Filter from '../components/Filter';
import OverviewChart from '../components/OverviewChart';

export default class PageOverview extends React.Component {
    
    state = {
        toasts: [],
        user: null,
        customer: null,

        filter: {
            customerId: '',
            firmwareId: '',
            configId: '',
            asicId: ''
        },
        interval: 600000,
                
        configHighcharts: {
            chart: {
                zoomType: 'x',
                height: '500px'
            },
            title: {
                text: 'Total Hashrate (last 7 days)',
            },
            yAxis: [
                { 
                    labels: {
                        format: '{value}Th/s',
                    },
                    title: {
                        text: 'Hashrate',                        
                    },
                    opposite: false
                },
                { 
                    labels: {
                        format: '{value}kW',
                    },
                    title: {
                        text: 'Power',
                    },
                    opposite: true            
                },
                { 
                    labels: {
                        format: '{value}',
                    },
                    title: {
                        text: 'Asics',
                    },
                    opposite: true, 
                    visible: false, 
                }
            ],            
            xAxis: {
                title: {
                    text: 'UTC time'
                },
                categories: []
            },
            tooltip: {
                shared: true
            },
            plotOptions: {
                spline: {
                    marker: {
                        enabled: false,
                    },
                },
                areaspline: {
                    marker: {
                        enabled: false,
                    },
                },
            },
            series: [
                {
                    name: 'Hashrate Avg(1hr)',
                    data: [],
                    type: 'areaspline',
                    yAxis: 0,
                    tooltip: {
                        valueSuffix: ' Th/s'
                    },
                    color: '#007af2',
                    fillColor: {
                        linearGradient: [0, 0, 0, 500],
                        stops: [
                            [0, 'rgba(0, 122, 242, 0.3)'],
                            [1, 'rgba(0, 122, 242, 0.1)']
                        ],
                    },
                },
                {
                    name: 'Power',
                    data: [],
                    type: 'spline',
                    yAxis: 1,
                    tooltip: {
                        valueSuffix: ' kW'
                    },
                    color: '#28a745'
                },
                {
                    name: 'Asics',
                    data: [],
                    type: 'spline',
                    yAxis: 2,
                    tooltip: {
                        valueSuffix: ''
                    },
                    color: '#203040',
                    dashStyle: 'Dot',
                    lineWidth: 1
                }
            ],            
        }
    }


    toast(title, content){
        let toasts = this.state.toasts;
        let i = toasts.push({
            title: title,
            content: content
        });
        this.setState({toasts},()=>{
            $('#toast_'+(i-1)).toast({delay: 5000});
            $('#toast_'+(i-1)).toast('show');
        });        
    }
    
    
    componentDidMount(){
        this.isMount = true;
        this.apiAuthCheck();
        this.intervalGetChartData = setInterval(this.apiGetChartData.bind(this), this.state.interval);
    }

    componentWillUnmount(){
        this.isMount = false;
        clearInterval(this.intervalGetChartData);
    }


    changeFilter(name, value){
        let filter = this.state.filter;
        filter[name] = value;
        
        if (name === 'customerId') { filter.firmwareId=''; filter.configId=''; filter.asicId=''; }
        if (name === 'firmwareId') { filter.configId=''; filter.asicId=''; }
        if (name === 'configId') { filter.asicId=''; }

        this.setState({filter},()=>{
            this.apiAuthCheck();
        });
    }

    apiAuthCheck(){
        let configHighcharts = this.state.configHighcharts;
        configHighcharts.series[0].data = [];
        configHighcharts.series[1].data = [];
        configHighcharts.series[2].data = [];
        if (this.isMount) this.setState({configHighcharts},
            ()=>{
                authCheck()
                .then((data)=>{
                    if ((data.status === 200)&&(data.data.user)){
                        if (this.isMount) this.setState({user: data.data.user});
                        this.apiGetCustomers();
                        this.apiGetFirmwares();
                        this.apiGetConfigs();
                        this.apiGetAsics();
                        this.apiGetChartData();
                        if (this.state.customerId) this.apiGetCustomer();
                    }else{
                        console.log(data);
                        this.props.history.push('/');
                    }          
                })
                .catch((err)=>{
                    console.log(err);
                    if (err.code === 'ECONNABORTED') {
                        switchApi();
                        this.apiAuthCheck();
                    }else{
                        this.props.history.push('/');
                    }
                });
            }
        );
        
    }


    apiGetFirmwares(){
        getFirmwares('?customerId='+this.state.filter.customerId)
        .then((data)=>{
            if ((data.status === 200)&&(data.data.firmwares)){
                if (this.isMount) this.setState({firmwares: data.data.firmwares});
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            //this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    apiGetConfigs(){
        getConfigs('?customerId='+this.state.filter.customerId+'&firmwareId='+this.state.filter.firmwareId)
        .then((data)=>{
            if ((data.status === 200)&&(data.data.configs)){
                if (this.isMount) this.setState({configs: data.data.configs});
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            //this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    apiGetAsics(){
        getAsics('?customerId='+this.state.filter.customerId+'&firmwareId='+this.state.filter.firmwareId+'&configId='+this.state.filter.configId+'&active=1')
        .then((data)=>{
            if ((data.status === 200)&&(data.data.asics)){
                if (this.isMount) this.setState({asics: data.data.asics});
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            //this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    apiGetCustomers(){
        getCustomers()
        .then((data)=>{
            if ((data.status === 200)&&(data.data.customers)){
                if (this.isMount) this.setState({customers: data.data.customers});
            }else{
                console.log(data);
            }          
        })
        .catch((err)=>{
            console.log(err);
            this.props.history.push('/');
        });
    }

    apiGetChartData(){
        getChartData('?customerId='+this.state.filter.customerId+'&firmwareId='+this.state.filter.firmwareId+'&configId='+this.state.filter.configId+'&asicId='+this.state.filter.asicId)
        .then((data)=>{
            if ((data.status === 200)&&(data.data.chartData)){
                let configHighcharts = this.state.configHighcharts;
                data.data.chartData.reverse().pop();
                configHighcharts.xAxis.categories = data.data.chartData.map((d)=>d.date);
                configHighcharts.series[0].data = data.data.chartData.map((d)=>Math.round(d.hash/10000)/100);
                configHighcharts.series[1].data = data.data.chartData.map((d)=>Math.round(d.power/10)/100);
                configHighcharts.series[2].data = data.data.chartData.map((d)=>d.asics);
                if (this.isMount) this.setState({configHighcharts});
            }else{
                console.log(data);
            }          
        })
        .catch((err)=>{
            console.log(err);
            //this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    render() {
        return (
            (!this.state.user)
            ?<div className="app"></div>
            :<div className="app">
                <Sidebar history={this.props.history} user={this.state.user} customer={this.state.customer} customerId={this.state.customerId}/>
                <div className="app-content">
                    <div className="page page-overview">
                        <div className="row">
                            <div className="col">
                                <h1 className="page-title">Overview 
                                </h1>                                
                            </div>
                            <div className="col d-flex justify-content-center align-items-center">
                                
                            </div>
                        </div>                
                        <div className="row mt-4">
                            <div className="col-lg-12">
                                <Filter 
                                    filter={this.state.filter}
                                    changeFilter={this.changeFilter.bind(this)}
                                    customers={(this.state.user.userType.permissions.find((el) => el.id === 58)) && this.state.customers}
                                    firmwares={(this.state.user.userType.permissions.find((el) => el.id === 55)) && this.state.firmwares}
                                    configs={(this.state.user.userType.permissions.find((el) => el.id === 56)) && this.state.configs}
                                    asics={this.state.asics}
                                />
                            </div>                                                  
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt-4">
                                <OverviewChart 
                                    configHighcharts={this.state.configHighcharts}
                                />
                            </div>                
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt-4">
                                
                            </div>                    
                        </div>                
                    </div>
                </div>
            </div>
        );
    }
}