import React from 'react';
import {switchApi, authCheck } from '../Api';
import $ from 'jquery';
import Toast from '../components/Toast';

export default class PageForgot extends React.Component {

    state = {
        email: '',
        toasts: [],
    }

    toast(title, content){
        let toasts = this.state.toasts;
        let i = toasts.push({
            title: title,
            content: content
        });
        this.setState({toasts},()=>{
            $('#toast_'+(i-1)).toast({delay: 5000});
            $('#toast_'+(i-1)).toast('show');
        });        
    }

    componentDidMount(){
        // temp redirect to login
        this.props.history.push('/login');
        this.isMount = true;
        this.apiAuthCheck();
    }

    componentWillUnmount(){
        this.isMount = false;
    }

    changeState(state, e){
        if (this.isMount) this.setState({[state]: e.target.value});
    }


    apiAuthCheck(){
        authCheck()
        .then((data)=>{
            if ((data.status === 200) && (data.data.user)){
                this.props.history.push('/customers');
            }else{
                console.log(data);
            }           
        })
        .catch((err)=>{
            console.log(err);
            if (err.code === 'ECONNABORTED') {
                switchApi();
                this.apiAuthCheck();
            }
        });
    }


    apiAuthReset(){
        this.toast('Error', 'Not implemented yet');
    }


    render() {
        return (
            <div className="app">
                <div className="app-content">
                    <div className="page page-form page-forgot">
                        <div className="toasts">
                            {(this.state.toasts) &&
                                this.state.toasts.map((toast, i)=>{
                                    return <Toast key={i} id={'toast_'+i} title={toast.title} content={toast.content} />
                                })
                            }
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="logo mb-5"><a href="/" >&nbsp;</a></div>
                                <h1 className="page-title">Reset password</h1>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input className="form-control" type="email" value={this.props._ant_pool1user}
                                    onChange={this.changeState.bind(this, '_ant_pool1user')} />
                                </div>
                                <div className="form-group page-form-actions mt-4">
                                    <div>
                                        <a href="/login"
                                            onClick={(e)=>{e.preventDefault(); this.props.history.push('/login');}}>
                                            Back to Login
                                        </a>
                                    </div>
                                    <button className="btn btn-primary"
                                        onClick={this.apiAuthReset.bind(this)}
                                    >Reset</button>
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}