import React from 'react';
import $ from 'jquery';
import img_lock from '../images/lock.svg';
import img_danger from '../images/danger.svg';

export default class AsicTable extends React.Component {

    state = {
        extra: []
    }

    toggle_extra(i, e){
        let ignoreClasses = [
            'custom-control-input', 
            'custom-control-label',
            'btn btn-link dropdown-toggle',
            'dropdown-item'
        ]
        if (ignoreClasses.includes(e.target.className)) return;
        let extra = this.state.extra;
        if (extra.includes(i)) {
            extra.splice(extra.indexOf(i), 1);
        }else{
            extra.push(i);            
        }
        this.setState({extra});
    }

    changeState(state, e){
        this.props.changeState(state, e.target.value);
    }

    dateConvert(date){
        let localDate = new Date(date);
        return localDate.toLocaleString();
    }

    editAsic(i){
        this.props.editFunction(i)
    }
    fanAdjust(i){
        this.props.fanAdjustFunction(i)
    }

    restartMiner(i){
        this.props.restartFunction(i)
    }

    stopMiner(i){
        this.props.stopFunction(i)
    }

    rebootAsic(i){
        this.props.rebootFunction(i)
    }

    uninstallAsic(i){
        this.props.uninstallFunction(i)
    }

    rso(i){
        this.props.rsoFunction(i)
    }

    rsc(i){
        this.props.rscFunction(i)
    }

    reinstall(i){
        this.props.reinstallFunction(i)
    }

    cancelJobs(i){
        this.props.cancelFunction(i)
    }

    
    styling(asic){
        let asicDate = new Date(asic.updatedAt);
        let newDate = new Date();
        let date24h = new Date(newDate.getTime() - 24*60*60*1000);
        if (asicDate < date24h) return'text-black-50';
        let mhs_av = ((asic.asicStats[0]) && parseFloat(asic.asicStats[0].mhs_av)) ? parseFloat(asic.asicStats[0].mhs_av) : 0;
        if (mhs_av <= 10000) return'text-danger';
    }

    formatUptime(uptime){
        let seconds = Number(uptime);
        var d = Math.floor(seconds / (3600*24));
        var h = Math.floor(seconds % (3600*24) / 3600);
        var m = Math.floor(seconds % 3600 / 60);
        var s = Math.floor(seconds % 60);
        
        var dDisplay = d > 0 ? d + "d" : "";
        var hDisplay = h > 0 ? h + "h" : "";
        var mDisplay = m > 0 ? m + "m" : "";
        var sDisplay = s > 0 ? s + "s" : "";

        let out = '';

        if (d > 0) { out = dDisplay + ' ' + hDisplay; }
        else if (h > 0) { out = hDisplay + ' ' + mDisplay; }
        else if (m > 0) { out = mDisplay + ' ' + sDisplay; }
        else { out = sDisplay; }

        return out;
    }

    formatHash(hash){
        let hashTh = parseFloat(hash)/1000000;
        return Math.round(hashTh*100)/100;
    }

    formatTemp(temp){
        let tempC = parseFloat(temp);
        return Math.round(tempC);
    }

    render() {
        return (
            <div className="component asic-table">
                <div className="panel pl-0 pr-0 pt-0 pb-0">
                    <table className="bordered">
                        <thead>
                            <tr>
                                <th className="pr-0 pt-0 pb-0" style={{width: '2.5rem'}}>
                                    <div className="custom-control custom-checkbox pt-2">
                                        <input type="checkbox" className="custom-control-input" id="selectAsicAll"/>
                                        <label className="custom-control-label" htmlFor="selectAsicAll"></label>
                                    </div>
                                </th>
                                <th>ID</th>
                                <th>Customer</th>                                
                                <th>Asic Type</th>
                                <th>IP</th>
                                <th>Config</th>
                                <th>Up</th>
                                <th>Th/s av</th>
                                <th>Stable</th>
                                <th>Power</th>
                                <th>Temp</th>
                                <th>Updated</th>
                                <th>Job state</th>
                                <th className="actions">
                                    <div className="dropdown">
                                        <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Actions</button>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <button className="dropdown-item" disabled={true}
                                            >Set Config</button>
                                            <button className="dropdown-item" disabled={true}                                                
                                            >Restart Miner</button>
                                            <button className="dropdown-item" disabled={true}                                                
                                            >Reboot System</button>
                                            <button className="dropdown-item" disabled={true}
                                            >Cancel Jobs</button>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {(this.props.asics) &&
                                this.props.asics.map((asic, i)=>{
                                    return <React.Fragment key={i}>
                                    <tr className={"has-extra " + this.styling(asic)} onClick={this.toggle_extra.bind(this, asic.id)}>
                                                <td className="pt-0 pb-0">
                                                <div className="custom-control custom-checkbox pt-2">
                                                    <input type="checkbox" className="custom-control-input" id={"selectAsic"+i}/>
                                                    <label className="custom-control-label" htmlFor={"selectAsic"+i}></label>
                                                </div>
                                                </td>
                                                <td>{asic.id}</td>
                                                <td>{asic.firmware.customer.company}</td>
                                                <td>{asic.firmware.asicType.model}</td>
                                                <td>{asic.ip}/{asic.pip}</td>
                                                <td>{asic.config.name}</td>
                                                <td>{(asic.asicStats[0] && asic.asicStats[0].elapsed) ? this.formatUptime(asic.asicStats[0].elapsed) : '0'}</td>
                                                <td>{(asic.asicStats[0] && asic.asicStats[0].mhs_av) ? this.formatHash(asic.asicStats[0].mhs_av) : '0.00'}</td>
                                                <td>{(asic.asicStats[0] && asic.asicStats[0].hash_stable) ? asic.asicStats[0].hash_stable : ''}</td>
                                                <td>{(asic.asicStats[0] && asic.asicStats[0].power) ? asic.asicStats[0].power : '0'}</td>
                                                <td>{(asic.asicStats[0] && asic.asicStats[0].temp) ? this.formatTemp(asic.asicStats[0].temp) : '0'}</td>
                                                <td>{this.dateConvert(asic.updatedAt)}</td>
                                                <td>{asic.status}</td>
                                                <td className="actions">
                                                    <div className="dropdown">
                                                        <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" 
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        </button>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <button className="dropdown-item" disabled={(asic.status !== 'ok')}
                                                                onClick={this.editAsic.bind(this, i)}
                                                            >Set Config &amp; Pools</button>
                                                            <button className="dropdown-item" disabled={(asic.status !== 'ok')}
                                                                onClick={this.fanAdjust.bind(this, i)}
                                                            >Fan Adjust</button>
                                                            <button className="dropdown-item" disabled={(asic.status !== 'ok')}
                                                                onClick={this.restartMiner.bind(this, i)}
                                                            >Restart Miner</button>
                                                            <button className="dropdown-item" disabled={(asic.status !== 'ok')}
                                                                onClick={this.stopMiner.bind(this, i)}
                                                            >Stop Miner</button>
                                                            <button className="dropdown-item" disabled={(asic.status !== 'ok')}
                                                                onClick={this.rebootAsic.bind(this, i)}
                                                            >Reboot System</button>
                                                            <button className="dropdown-item" disabled={(asic.status !== 'ok')}
                                                                onClick={this.uninstallAsic.bind(this, i)}
                                                            >Reset to default FW</button>
                                                            {((this.props.user.userType.scope === 'admin')&&(!asic.port)) &&
                                                            <button className="dropdown-item" disabled={(asic.status !== 'ok')}
                                                                onClick={this.rso.bind(this, i)}
                                                            >Set SROO</button>
                                                            }
                                                            {((this.props.user.userType.scope === 'admin')&&(asic.port)) &&
                                                            <button className="dropdown-item" disabled={(asic.status !== 'ok')}
                                                                onClick={this.rsc.bind(this, i)}
                                                            >Set NESROO</button>
                                                            }
                                                            <button className="dropdown-item" disabled={(asic.status !== 'ok')}
                                                                onClick={this.reinstall.bind(this, i)}
                                                            >Upgrade / Reinstall</button>                                                            
                                                            {(this.props.user.userType.scope === 'admin') &&
                                                            <button className="dropdown-item" disabled={(!asic.status)||(asic.status === 'ok')}
                                                                onClick={this.cancelJobs.bind(this, i)}
                                                            >Cancel All Jobs</button>
                                                            }
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            {(this.state.extra.includes(asic.id)) &&
                                            <tr className="extra">
                                                <td colSpan="14" className="p-0">
                                                <table className="small">
                                                            <tbody>
                                                            <tr>
                                                                <td>
                                                                    <h2 className="m-0">{asic.hwData.split('-').slice(0,3).join(' ')}</h2>
                                                                    <p className="m-0">{asic.hwData.split('-').slice(3).join('-')}</p>
                                                                </td>                                                                
                                                                <td className="d-flex">
                                                                    <div className="panel p-2 m-2">
                                                                        <strong>Status: </strong>{(asic.asicStats[0]) ? asic.asicStats[0].status : ''}
                                                                    </div>
                                                                    <div className="panel p-2 m-2">
                                                                        <strong>Cooling: </strong>{(asic.asicStats[0] && asic.asicStats[0].liq === 'false') ? 'AIR' : 'LIQ'}
                                                                    </div>
                                                                    <div className="panel p-2 m-2">
                                                                        <strong>Env: </strong>{(asic.asicStats[0] && asic.asicStats[0].temp_env) ? parseInt(asic.asicStats[0].temp_env) : '--'}&deg;C
                                                                    </div>
                                                                    {(this.props.user.userType.scope === 'admin') &&
                                                                    <div className="panel p-2 m-2">
                                                                        <strong>Port: </strong>{asic.port}
                                                                    </div>
                                                                    }
                                                                    {(this.props.user.userType.scope === 'admin') &&
                                                                    <div className="panel p-2 m-2">
                                                                    <strong>Lock: </strong>{(asic.sshLock) ? <img className="ml-2 mb-2" src={img_lock} alt="lock"/> : <img className="ml-2 mb-2" src={img_danger} alt="danger"/>}
                                                                    </div>
                                                                    }
                                                                    <div className="panel p-2 m-2">
                                                                        v.{asic.version}
                                                                    </div>
                                                                </td>                                                                
                                                            </tr>                                                            
                                                            </tbody>
                                                        </table>
                                                        <div className="d-flex justify-content-between small pl-4 pr-4 pt-2 pb-2">
                                                            <div className="d-flex">
                                                                <div><strong>Messages: </strong></div>
                                                                {(asic.asicMessages) && 
                                                                    asic.asicMessages.map((message, i)=>{
                                                                        return <div key={i} className="pl-3"><a href="#!" onClick={(e)=>{e.preventDefault();}} title={this.dateConvert(message.updatedAt)}>
                                                                            {(!message.hidden) &&
                                                                                <div>
                                                                                <span className="small">{this.dateConvert(message.updatedAt)}<br/></span>
                                                                                <span className="text-bold">{message.name}</span>
                                                                                </div>
                                                                            }
                                                                            </a>
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                            <div className="d-flex">
                                                                <div><strong>Errors: </strong></div>
                                                                <div className="text-danger">&nbsp; {(asic.asicStats[0]) ? asic.asicStats[0].errors : ''}</div>
                                                                <div></div>
                                                                <div></div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div><strong>Test data: </strong></div>
                                                                <div>Link</div>
                                                            </div>                                                            
                                                        </div>

                                                        <table className="small">
                                                            <tbody>
                                                            <tr>
                                                                <td className="text-bold">
                                                                    
                                                                    Accepted:<br/>
                                                                    Rejected:<br/>
                                                                </td>
                                                                <td>
                                                                    
                                                                    {(asic.asicStats[0]) ? asic.asicStats[0].accepted : ''}<br/>
                                                                    {(asic.asicStats[0]) ? asic.asicStats[0].rejected : ''}<br/>
                                                                </td>
                                                                <td>&nbsp;</td>
                                                                <td className="text-bold">
                                                                    Th/s_5s:<br/>
                                                                    Th/s_1m:<br/>
                                                                    Th/s_5m:<br/>                                                                        
                                                                    Th/s_15m:<br/>
                                                                </td>
                                                                <td>
                                                                    {(asic.asicStats[0] && asic.asicStats[0].mhs_5s) ? this.formatHash(asic.asicStats[0].mhs_5s) : '0.00'}<br/>
                                                                    {(asic.asicStats[0] && asic.asicStats[0].mhs_1m) ? this.formatHash(asic.asicStats[0].mhs_1m) : '0.00'}<br/>
                                                                    {(asic.asicStats[0] && asic.asicStats[0].mhs_5m) ? this.formatHash(asic.asicStats[0].mhs_5m) : '0.00'}<br/>
                                                                    {(asic.asicStats[0] && asic.asicStats[0].mhs_15m) ? this.formatHash(asic.asicStats[0].mhs_15m) : '0.00'}<br/>
                                                                </td>
                                                                <td>&nbsp;</td>
                                                                <td className="text-bold">
                                                                    Chips_0:<br/>
                                                                    Chips_1:<br/>
                                                                    Chips_2:<br/>
                                                                </td>
                                                                <td>
                                                                    {(asic.asicStats[0] && asic.asicStats[0].chips_0) ? asic.asicStats[0].chips_0 : ''}<br/>
                                                                    {(asic.asicStats[0] && asic.asicStats[0].chips_1) ? asic.asicStats[0].chips_1 : ''}<br/>
                                                                    {(asic.asicStats[0] && asic.asicStats[0].chips_2) ? asic.asicStats[0].chips_2 : ''}<br/>
                                                                </td>
                                                                <td>&nbsp;</td>
                                                                <td className="text-bold">
                                                                    Fifo_0:<br/>
                                                                    Fifo_1:<br/>
                                                                    Fifo_2:<br/>
                                                                </td>
                                                                <td>
                                                                    {(asic.asicStats[0] && asic.asicStats[0].fifo_0) ? asic.asicStats[0].fifo_0 : ''}<br/>
                                                                    {(asic.asicStats[0] && asic.asicStats[0].fifo_1) ? asic.asicStats[0].fifo_1 : ''}<br/>
                                                                    {(asic.asicStats[0] && asic.asicStats[0].fifo_2) ? asic.asicStats[0].fifo_2 : ''}<br/>
                                                                </td>
                                                                <td>&nbsp;</td>
                                                                <td className="text-bold">
                                                                    Freq_0:<br/>
                                                                    Freq_1:<br/>
                                                                    Freq_2:<br/>
                                                                    Freq_Av:<br/>
                                                                </td>
                                                                <td>
                                                                    {(asic.asicStats[0] && asic.asicStats[0].freq_0) ? asic.asicStats[0].freq_0 : ''}<br/>
                                                                    {(asic.asicStats[0] && asic.asicStats[0].freq_1) ? asic.asicStats[0].freq_1 : ''}<br/>
                                                                    {(asic.asicStats[0] && asic.asicStats[0].freq_2) ? asic.asicStats[0].freq_2 : ''}<br/>
                                                                    {(asic.asicStats[0] && asic.asicStats[0].freq_av) ? asic.asicStats[0].freq_av : ''}<br/>
                                                                </td>
                                                                <td>&nbsp;</td>
                                                                <td className="text-bold">
                                                                    Upfreq_0:<br/>
                                                                    Upfreq_1:<br/>
                                                                    Upfreq_2:<br/>
                                                                </td>
                                                                <td>
                                                                    {(asic.asicStats[0] && asic.asicStats[0].upf_0) ? asic.asicStats[0].upf_0 : ''}<br/>
                                                                    {(asic.asicStats[0] && asic.asicStats[0].upf_1) ? asic.asicStats[0].upf_1 : ''}<br/>
                                                                    {(asic.asicStats[0] && asic.asicStats[0].upf_2) ? asic.asicStats[0].upf_2 : ''}<br/>
                                                                </td>
                                                                <td>&nbsp;</td>
                                                                <td className="text-bold">
                                                                    Fan_In:<br/>
                                                                    Fan_Out:<br/>
                                                                    Temp_0:<br/>
                                                                    Temp_1:<br/>
                                                                    Temp_2:<br/>
                                                                    Temp_Env:<br/>                                                                    
                                                                </td>
                                                                <td>
                                                                    {(asic.asicStats[0] && asic.asicStats[0].fan_speed_in) ? asic.asicStats[0].fan_speed_in : ''}<br/>
                                                                    {(asic.asicStats[0] && asic.asicStats[0].fan_speed_out) ? asic.asicStats[0].fan_speed_out : ''}<br/>
                                                                    {(asic.asicStats[0] && asic.asicStats[0].temp_0) ? this.formatTemp(asic.asicStats[0].temp_0) : ''}<br/>
                                                                    {(asic.asicStats[0] && asic.asicStats[0].temp_1) ? this.formatTemp(asic.asicStats[0].temp_1) : ''}<br/>
                                                                    {(asic.asicStats[0] && asic.asicStats[0].temp_2) ? this.formatTemp(asic.asicStats[0].temp_2) : ''}<br/>
                                                                    {(asic.asicStats[0] && asic.asicStats[0].temp_env) ? this.formatTemp(asic.asicStats[0].temp_env) : ''}<br/>                                                                    
                                                                </td>
                                                                <td>&nbsp;</td>
                                                                <td className="text-bold">
                                                                    Fan,%:<br/>
                                                                    Fan_Detected:<br/>
                                                                    Auto_Speed:<br/>
                                                                    Target_Temp:<br/>
                                                                    Protect_Temp:<br/>
                                                                    Adjust_Temp:<br/>
                                                                </td>
                                                                <td>
                                                                    {(asic.asicStats[0] && asic.asicStats[0].fan_pwm) ? asic.asicStats[0].fan_pwm : ''}<br/>
                                                                    {(asic.asicStats[0].fan_detected) ? asic.asicStats[0].fan_detected : ''}<br/>
                                                                    {(asic.asicStats[0].fan_speed_auto_adjust) ? asic.asicStats[0].fan_speed_auto_adjust : ''}<br/>
                                                                    {(asic.asicStats[0].fan_target_temp) ? asic.asicStats[0].fan_target_temp : ''}<br/>       
                                                                    {(asic.asicStats[0].fan_protect_temp) ? asic.asicStats[0].fan_protect_temp : ''}<br/>
                                                                    {(asic.asicStats[0].fan_adjust_temp) ? asic.asicStats[0].fan_adjust_temp : ''}<br/>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    
                                                </td>
                                            </tr>
                                            }
                                        </React.Fragment>
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}