import React from 'react';

export default class Toast extends React.Component {

    render() {

        return (
            <div id={this.props.id} className="toast" role="alert" aria-live="assertive" aria-atomic="true">
                <div className={"toast-header "+ this.props.title.toLowerCase()}>
                    <strong className="mr-auto">{this.props.title}</strong>
                    <button type="button" className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                    <span aria-hidden="true">&times;</span></button>
                </div>
                <div className="toast-body">
                    {this.props.content}
                </div>
            </div>
        );
    }
}