import React from 'react';

export default class StatsTable extends React.Component {

    state = {
        extra: []
    }

    toggle_extra(i){
        let extra = this.state.extra;
        if (extra.includes(i)) {
            extra.splice(extra.indexOf(i), 1);
        }else{
            extra.push(i);            
        }
        this.setState({extra});
    }

    changeState(state, e){
        this.props.changeState(state, e.target.value);
    }

    dateConvert(date){
        let localDate = new Date(date);
        return localDate.toLocaleString();
    }

    editAsic(i){
        this.props.editFunction(i)
    }

    styling(stat){
        let mhs_av = (parseFloat(stat.mhs_av)) ? parseFloat(stat.mhs_av) : 0;
        if (mhs_av <= 10000) return'text-danger';
    }

    formatUptime(uptime){
        let seconds = Number(uptime);
        var d = Math.floor(seconds / (3600*24));
        var h = Math.floor(seconds % (3600*24) / 3600);
        var m = Math.floor(seconds % 3600 / 60);
        var s = Math.floor(seconds % 60);
        
        var dDisplay = d > 0 ? d + "d" : "";
        var hDisplay = h > 0 ? h + "h" : "";
        var mDisplay = m > 0 ? m + "m" : "";
        var sDisplay = s > 0 ? s + "s" : "";

        let out = '';

        if (d > 0) { out = dDisplay + ' ' + hDisplay; }
        else if (h > 0) { out = hDisplay + ' ' + mDisplay; }
        else if (m > 0) { out = mDisplay + ' ' + sDisplay; }
        else { out = sDisplay; }

        return out;
    }

    formatHash(hash){
        let hashTh = parseFloat(hash)/1000000;
        return Math.round(hashTh*100)/100;
    }

    formatTemp(temp){
        let tempC = parseFloat(temp);
        return Math.round(tempC);
    }

    render() {
        return (
            <div className="component user-table">
                <div className="panel pl-0 pr-0 pt-0 pb-0">
                    <table className="bordered">
                        <thead>
                            <tr>
                                <th>Time</th>
                                <th>ID</th>
                                <th>IP</th>
                                <th>Config</th>
                                <th>Elapsed</th>
                                <th>Th/s av</th>
                                <th>Stable</th>
                                <th>Power</th>
                                <th>Volt</th>
                                <th>Curr</th>
                                <th>Temp,&deg;C</th>
                                <th>Fan,%</th>
                                <th>Errors</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(this.props.stats) &&
                                this.props.stats.map((stat, i)=>{
                                    return <React.Fragment key={i}>
                                        <tr className={"has-extra " + this.styling(stat)} onClick={this.toggle_extra.bind(this, stat.id)}>
                                                <td>{this.dateConvert(stat.updatedAt)}</td>
                                                <td>{stat.asicId}</td>
                                                <td>{stat.asic.ip}/{stat.asic.pip}</td>
                                                <td>{stat.asic.config.name}</td>
                                                <td>{(stat.elapsed) ? this.formatUptime(stat.elapsed) : '0'}</td>
                                                <td>{(stat.mhs_av) ? this.formatHash(stat.mhs_av) : '0.00'}</td>
                                                <td>{stat.hash_stable}</td>
                                                <td>{(stat.power) ? stat.power : '0'}</td>
                                                <td>{stat.voltage}</td>
                                                <td>{stat.power_current.substring(0, 3)}</td>
                                                <td>{(stat.temp) ? this.formatTemp(stat.temp) : '0'}</td>
                                                <td>{(stat.fan_pwm) ? stat.fan_pwm : ''}</td>
                                                <td>{(stat.errors) ? stat.errors : ''}</td>
                                            </tr>
                                            {(this.state.extra.includes(stat.id)) &&
                                                <tr className="extra">
                                                    <td colSpan="13" className="p-0">
                                                        
                                                            <table className="small">
                                                                <tbody>
                                                                <tr>
                                                                    <td className="text-bold">
                                                                        Status:<br/>
                                                                        Liquid:<br/>
                                                                        Accepted:<br/>
                                                                        Rejected:<br/>
                                                                    </td>
                                                                    <td>
                                                                        {(stat) ? stat.status : ''}<br/>
                                                                        {(stat) ? stat.liq : ''}<br/>
                                                                        {(stat) ? stat.accepted : ''}<br/>
                                                                        {(stat) ? stat.rejected : ''}<br/>
                                                                    </td>
                                                                    <td>&nbsp;</td>
                                                                    <td className="text-bold">
                                                                        Th/s_5s:<br/>
                                                                        Th/s_1m:<br/>
                                                                        Th/s_5m:<br/>                                                                        
                                                                        Th/s_15m:<br/>
                                                                    </td>
                                                                    <td>
                                                                        {(stat.mhs_5s) ? this.formatHash(stat.mhs_5s) : '0.00'}<br/>
                                                                        {(stat.mhs_1m) ? this.formatHash(stat.mhs_1m) : '0.00'}<br/>
                                                                        {(stat.mhs_5m) ? this.formatHash(stat.mhs_5m) : '0.00'}<br/>
                                                                        {(stat.mhs_15m) ? this.formatHash(stat.mhs_15m) : '0.00'}<br/>
                                                                    </td>
                                                                    <td>&nbsp;</td>
                                                                    <td className="text-bold">
                                                                        Chips_0:<br/>
                                                                        Chips_1:<br/>
                                                                        Chips_2:<br/>
                                                                    </td>
                                                                    <td>
                                                                        {(stat.chips_0) ? stat.chips_0 : ''}<br/>
                                                                        {(stat.chips_1) ? stat.chips_1 : ''}<br/>
                                                                        {(stat.chips_2) ? stat.chips_2 : ''}<br/>
                                                                    </td>
                                                                    <td>&nbsp;</td>
                                                                    <td className="text-bold">
                                                                        Fifo_0:<br/>
                                                                        Fifo_1:<br/>
                                                                        Fifo_2:<br/>
                                                                    </td>
                                                                    <td>
                                                                        {(stat.fifo_0) ? stat.fifo_0 : ''}<br/>
                                                                        {(stat.fifo_1) ? stat.fifo_1 : ''}<br/>
                                                                        {(stat.fifo_2) ? stat.fifo_2 : ''}<br/>
                                                                    </td>
                                                                    <td>&nbsp;</td>
                                                                    <td className="text-bold">
                                                                        Freq_0:<br/>
                                                                        Freq_1:<br/>
                                                                        Freq_2:<br/>
                                                                        Freq_Av:<br/>
                                                                    </td>
                                                                    <td>
                                                                        {(stat.freq_0) ? stat.freq_0 : ''}<br/>
                                                                        {(stat.freq_1) ? stat.freq_1 : ''}<br/>
                                                                        {(stat.freq_2) ? stat.freq_2 : ''}<br/>
                                                                        {(stat.freq_av) ? stat.freq_av : ''}<br/>
                                                                    </td>
                                                                    <td>&nbsp;</td>
                                                                    <td className="text-bold">
                                                                        Upfreq_0:<br/>
                                                                        Upfreq_1:<br/>
                                                                        Upfreq_2:<br/>
                                                                    </td>
                                                                    <td>
                                                                        {(stat.upf_0) ? stat.upf_0 : ''}<br/>
                                                                        {(stat.upf_1) ? stat.upf_1 : ''}<br/>
                                                                        {(stat.upf_2) ? stat.upf_2 : ''}<br/>
                                                                    </td>
                                                                    <td>&nbsp;</td>
                                                                    <td className="text-bold">
                                                                        Fan_In:<br/>
                                                                        Fan_Out:<br/>
                                                                        Temp_0:<br/>
                                                                        Temp_1:<br/>
                                                                        Temp_2:<br/>
                                                                        Temp_Env:<br/>                                                                        
                                                                    </td>
                                                                    <td>
                                                                        {(stat.fan_speed_in) ? stat.fan_speed_in : ''}<br/>
                                                                        {(stat.fan_speed_out) ? stat.fan_speed_out : ''}<br/>       
                                                                        {(stat.temp_0) ? this.formatTemp(stat.temp_0) : ''}<br/>
                                                                        {(stat.temp_1) ? this.formatTemp(stat.temp_1) : ''}<br/>
                                                                        {(stat.temp_2) ? this.formatTemp(stat.temp_2) : ''}<br/>
                                                                        {(stat.temp_env) ? this.formatTemp(stat.temp_env) : ''}<br/>                                                                        
                                                                    </td>
                                                                    <td>&nbsp;</td>
                                                                    <td className="text-bold">
                                                                        Fan_Detected:<br/>
                                                                        Auto_Speed:<br/>
                                                                        Target_Temp:<br/>
                                                                        Protect_Temp:<br/>
                                                                        Adjust_Temp:<br/>
                                                                    </td>
                                                                    <td>
                                                                        {(stat.fan_detected) ? stat.fan_detected : ''}<br/>
                                                                        {(stat.fan_speed_auto_adjust) ? stat.fan_speed_auto_adjust : ''}<br/>
                                                                        {(stat.fan_target_temp) ? stat.fan_target_temp : ''}<br/>       
                                                                        {(stat.fan_protect_temp) ? stat.fan_protect_temp : ''}<br/>
                                                                        {(stat.fan_adjust_temp) ? stat.fan_adjust_temp : ''}<br/>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        
                                                    </td>
                                                </tr>
                                                }
                                        </React.Fragment>
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}