import React from 'react';
import {switchApi, authCheck, getCustomers, getAsicTypes, getFirmwares, getConfigs, getConfig, addConfig, editConfig, delConfig } from '../Api';
import Modal from '../components/Modal';
import ModalConfig from '../components/ModalConfig';
import $ from 'jquery';
import Toast from '../components/Toast';
import Filter from '../components/Filter';
import Sidebar from '../components/Sidebar';
import ConfigTable from '../components/ConfigTable';

export default class PageConfigs extends React.Component {

    state = {
        toasts: [],
        filter: {
            customerId: '',
            firmwareId: '',
            asicTypeId: ''
        },
        firmwares: [],
        configs: [],
        config: {},
        _id: 0,
        _name: '',
        _description: '',
        _firmwareId: 0,
        _preset: '',
        _config: ''
    }


    toast(title, content){
        let toasts = this.state.toasts;
        let i = toasts.push({
            title: title,
            content: content
        });
        this.setState({toasts},()=>{
            $('#toast_'+(i-1)).toast({delay: 5000});
            $('#toast_'+(i-1)).toast('show');
        });        
    }


    changeFilter(name, value){
        let filter = this.state.filter;
        filter[name] = value;
        
        if (name === 'customerId') { filter.firmwareId=''; }
        if (name === 'asicTypeId') { filter.firmwareId=''; }

        this.setState({filter},()=>{
            this.apiAuthCheck();
        });
    }


    componentDidMount(){
        this.isMount = true;
        this.apiAuthCheck();
    }


    componentWillUnmount(){
        this.isMount = false;
    }


    changeState(state, value){
        if (this.isMount) this.setState({[state]: value});
    }


    apiAuthCheck(){
        authCheck()
        .then((data)=>{
            if ((data.status === 200)&&(data.data.user)){
                if (this.isMount) this.setState({user: data.data.user});
                this.apiGetCustomers();
                this.apiGetAsicTypes();
                this.apiGetFirmwares();
                this.apiGetConfigs();
            }else{
                console.log(data);
                this.props.history.push('/');
            }          
        })
        .catch((err)=>{
            console.log(err);
            if (err.code === 'ECONNABORTED') {
                switchApi();
                this.apiAuthCheck();
            }else{
                this.props.history.push('/');
            }
        });
    }


    apiGetCustomers(){
        getCustomers()
        .then((data)=>{
            if ((data.status === 200)&&(data.data.customers)){
                if (this.isMount) this.setState({customers: data.data.customers});                
            }else{
                console.log(data);
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    apiGetAsicTypes(){
        getAsicTypes()
        .then((data)=>{
            if ((data.status === 200)&&(data.data.asictypes)){
                if (this.isMount) this.setState({asictypes: data.data.asictypes});                
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    apiGetFirmwares(){
        getFirmwares('?customerId='+this.state.filter.customerId+'&asicTypeId='+this.state.filter.asicTypeId)
        .then((data)=>{
            if ((data.status === 200)&&(data.data.firmwares)){
                if (this.isMount) this.setState({firmwares: data.data.firmwares});                
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }

    
    apiGetConfigs(){
        getConfigs('?customerId='+this.state.filter.customerId+'&firmwareId='+this.state.filter.firmwareId+'&asicTypeId='+this.state.filter.asicTypeId)
        .then((data)=>{
            if ((data.status === 200)&&(data.data.configs)){
                if (this.isMount) this.setState({configs: data.data.configs});
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }

    
    modalAddConfig(){
        this.setState({
            _name: '',
            _description: '',
            _firmwareId: 0,
            _preset: '',
            _config: ''
        }, ()=>{
            $('#modalAddConfig').modal('show');
        });
    }


    apiAddConfig(){
        addConfig({
            name: this.state._name,
            description: this.state._description,
            firmwareId: this.state._firmwareId,
            preset: this.state._preset,
            config: this.state._config
        })
        .then((data)=>{
            if ((data.status === 200)&&(!data.data.error)){
                $('#modalAddConfig').modal('hide');
                this.toast('Success', 'Saved');
                this.apiGetConfigs();
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    modalCopyConfig(i){
        let _id = this.state.configs[i].id;
        getConfig(_id)
        .then((data)=>{
            if ((data.status === 200)&&(data.data.config)){
                if (this.isMount) this.setState({
                    _id,
                    _name: (data.data.config.name) ? data.data.config.name : '',
                    _description: (data.data.config.description) ? data.data.config.description : '',
                    _firmwareId: (data.data.config.firmware.id) ? data.data.config.firmware.id : 0,
                    _preset: (data.data.config.preset) ? data.data.config.preset : '',
                    _config: (data.data.config.config) ? data.data.config.config : ''
                }, ()=>{
                    $('#modalAddConfig').modal('show');
                });
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
        });        
    }

    modalEditConfig(i){
        let _id = this.state.configs[i].id;
        getConfig(_id)
        .then((data)=>{
            if ((data.status === 200)&&(data.data.config)){
                if (this.isMount) this.setState({
                    _id,
                    _name: (data.data.config.name) ? data.data.config.name : '',
                    _description: (data.data.config.description) ? data.data.config.description : '',
                    _firmwareId: (data.data.config.firmware.id) ? data.data.config.firmware.id : 0,
                    _preset: (data.data.config.preset) ? data.data.config.preset : '',
                    _config: (data.data.config.config) ? data.data.config.config : ''
                }, ()=>{
                    $('#modalEditConfig').modal('show');
                });
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });        
    }

    
    apiEditConfig(){
        editConfig(this.state._id, {
            name: this.state._name,
            description: this.state._description,
            firmwareId: this.state._firmwareId,
            preset: this.state._preset,
            config: this.state._config
        })
        .then((data)=>{
            if ((data.status === 200)&&(!data.data.error)){
                $('#modalEditConfig').modal('hide');
                this.toast('Success', 'Saved');
                this.apiGetConfigs();
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    modalDelConfig(i){
        let _id = this.state.configs[i].id;
        this.setState({
            _id
        }, ()=>{
            $('#modalDelConfig').modal('show');
        });
    }

    
    apiDelConfig(){
        delConfig(this.state._id)
        .then((data)=>{
            if ((data.status === 200)&&(!data.data.error)){
                $('#modalDelConfig').modal('hide');
                this.toast('Success', 'Deleted');
                this.apiGetConfigs();
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    render() {
        return (
            (!this.state.user)
            ?<div className="app"></div>
            :<div className="app">
                <Sidebar history={this.props.history} user={this.state.user} customer={this.state.customer} customerId={this.state.customerId}/>
                <div className="app-content">
                    <div className="page page-configs">
                        <div className="toasts">
                            {(this.state.toasts) &&
                                this.state.toasts.map((toast, i)=>{
                                    return <Toast key={i} id={'toast_'+i} title={toast.title} content={toast.content} />
                                })
                            }
                        </div>
                        <div className="row">
                            <div className="col">
                                <h1 className="page-title">Configs                              
                                </h1>
                            </div>
                            <div className="col text-right">
                                {(this.state.user.userType.permissions.find((el) => el.id === 15)) &&
                                    <button className="btn btn-primary"
                                    onClick={this.modalAddConfig.bind(this)}
                                    >New Config</button>
                                }
                            </div>
                        </div>             
                        <div className="row mt-4">
                            <div className="col-lg-12">
                                <Filter 
                                    filter={this.state.filter}
                                    changeFilter={this.changeFilter.bind(this)}
                                    customers={(this.state.user.userType.permissions.find((el) => el.id === 58)) && this.state.customers}                                    
                                    asictypes={this.state.asictypes}
                                />
                            </div>                                                  
                        </div>   
                        <div className="row">
                            <div className="col-lg-12">
                                <ConfigTable 
                                    user={this.state.user}
                                    configs={this.state.configs}
                                    editFunction={this.modalEditConfig.bind(this)}
                                    copyFunction={this.modalCopyConfig.bind(this)}
                                    deleteFunction={this.modalDelConfig.bind(this)} 
                                />
                            </div>
                        </div>                
                    </div>
                </div>
                <ModalConfig
                    id="modalAddConfig"
                    title="Add Config"
                    changeState={this.changeState.bind(this)}
                    saveFunction={this.apiAddConfig.bind(this)}
                    firmwares={this.state.firmwares}
                    _name={this.state._name}
                    _description={this.state._description}
                    _firmwareId={this.state._firmwareId}
                    _preset={this.state._preset}
                    _config={this.state._config}
                />
                <ModalConfig
                    id="modalEditConfig"
                    title="Edit Config"
                    changeState={this.changeState.bind(this)}
                    saveFunction={this.apiEditConfig.bind(this)}
                    firmwares={this.state.firmwares}
                    _name={this.state._name}
                    _description={this.state._description}
                    _firmwareId={this.state._firmwareId}
                    _preset={this.state._preset}
                    _config={this.state._config}
                />                
                <Modal 
                    id="modalDelConfig"
                    title="Delete Config"
                    content="Are you sure?"
                    confirmText="Delete"
                    confirmFunction={this.apiDelConfig.bind(this)}
                />
            </div>        
        );
    }
}