import React from 'react';

export default class Modal extends React.Component {

    render() {

        return (
            <div id={this.props.id} className="modal fade" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">{this.props.title}</h3>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {this.props.content}
                        </div>
                        <div className="modal-result">
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="action btn btn-primary"
                                onClick={this.props.confirmFunction}>{this.props.confirmText}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}