import React from 'react';
import $ from 'jquery';
export default class ModalPass extends React.Component {


    changeState(state, e){
        this.props.changeState(state, e.target.value);
    }
   
    render() {

        return (
            <div id={this.props.id} className="modal fade" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">{this.props.title}</h3>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="form-group col-12">
                                    <label>New Password</label>
                                    <input className="form-control" type="password" value={this.props._password}
                                    onChange={this.changeState.bind(this, '_password')} autoComplete="new-password"/>
                                </div>                                
                            </div>
                            <div className="row">
                                <div className="form-group col-12">
                                    <label>Repeat Password</label>
                                    <input className="form-control" type="password" value={this.props._password2}
                                    onChange={this.changeState.bind(this, '_password2')} autoComplete="new-password2"/>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="action btn btn-primary"
                                onClick={this.props.saveFunction}
                                >Reset</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}