import React from 'react';
import {switchApi, authCheck, getCustomers, getFirmwares, getAsicTypes, getFirmware, addFirmware, editFirmware, delFirmware } from '../Api';
import Modal from '../components/Modal';
import ModalFW from '../components/ModalFW';
import $ from 'jquery';
import Toast from '../components/Toast';
import Filter from '../components/Filter';
import Sidebar from '../components/Sidebar';
import FirmwareTable from '../components/FirmwareTable';

export default class PageFirmwares extends React.Component {

    state = {
        toasts: [],
        filter: {
            customerId: '',
            asicTypeId: '',
        },
        asictypes: [],
        customers: [],
        firmwares: [],
        firmware: {},
        _id: 0,
        _uid: '',
        _asicTypeId: 0,
        _customerId: 0,
        _publicKey: '',
        _privateKey: '',
        _maxAsics: 0,
        _devPools: '',
        _devQuotas: '',
        _ipRange: '',
        _feeRes: 0,
        _feeMan: 0,
        _feeDev: 0
    }


    toast(title, content){
        let toasts = this.state.toasts;
        let i = toasts.push({
            title: title,
            content: content
        });
        this.setState({toasts},()=>{
            $('#toast_'+(i-1)).toast({delay: 5000});
            $('#toast_'+(i-1)).toast('show');
        });        
    }


    changeFilter(name, value){
        let filter = this.state.filter;
        filter[name] = value;
        
        this.setState({filter},()=>{
            this.apiAuthCheck();
        });
    }


    componentDidMount(){
        this.isMount = true;
        this.apiAuthCheck();
    }


    componentWillUnmount(){
        this.isMount = false;
    }


    changeState(state, value){
        if (this.isMount) this.setState({[state]: value});
    }


    apiAuthCheck(){
        authCheck()
        .then((data)=>{
            if ((data.status === 200)&&(data.data.user)){
                if (this.isMount) this.setState({user: data.data.user});
                this.apiGetAsicTypes();
                this.apiGetCustomers();
                this.apiGetFirmwares();                
            }else{
                console.log(data);
                this.props.history.push('/');
            }          
        })
        .catch((err)=>{
            console.log(err);
            if (err.code === 'ECONNABORTED') {
                switchApi();
                this.apiAuthCheck();
            }else{
                this.props.history.push('/');
            }
        });
    }


    apiGetCustomers(){
        getCustomers()
        .then((data)=>{
            if ((data.status === 200)&&(data.data.customers)){
                if (this.isMount) this.setState({customers: data.data.customers});                
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    apiGetAsicTypes(){
        getAsicTypes()
        .then((data)=>{
            if ((data.status === 200)&&(data.data.asictypes)){
                if (this.isMount) this.setState({asictypes: data.data.asictypes});                
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    apiGetFirmwares(){
        getFirmwares('?customerId='+this.state.filter.customerId+'&asicTypeId='+this.state.filter.asicTypeId)
        .then((data)=>{
            if ((data.status === 200)&&(data.data.firmwares)){
                if (this.isMount) this.setState({firmwares: data.data.firmwares});
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    modalAddFirmware(){
        this.setState({
            _uid: '',
            _asicTypeId: 0,
            _customerId: 0,
            _publicKey: '',
            _privateKey: '',
            _maxAsics: 10,
            _devPools: '',
            _devQuotas: '',
            _ipRange: '',
            _feeRes: 0,
            _feeMan: 0,
            _feeDev: 5,
        }, ()=>{
            $('#modalAddFirmware').modal('show');
        });
    }


    apiAddFirmware(){
        addFirmware({
            uid: this.state._uid,
            asicTypeId: this.state._asicTypeId,
            customerId: this.state._customerId,
            publicKey: this.state._publicKey,
            privateKey: this.state._privateKey,
            maxAsics: this.state._maxAsics,
            devPools: this.state._devPools,
            devQuotas: this.state._devQuotas,
            ipRange: this.state._ipRange,
            feeRes: this.state._feeRes,
            feeMan: this.state._feeMan,
            feeDev: this.state._feeDev,
        })
        .then((data)=>{
            if ((data.status === 200)&&(!data.data.error)){
                $('#modalAddFirmware').modal('hide');
                this.toast('Success', 'Pair of keys generated');
                setTimeout(()=>{this.toast('Success', 'Firmware Saved')}, 500);
                this.apiGetFirmwares();
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    modalEditFirmware(i){
        let _id = this.state.firmwares[i].id;
        getFirmware(_id)
        .then((data)=>{
            if ((data.status === 200)&&(data.data.firmware)){
                if (this.isMount) this.setState({
                    _id,
                    _uid: (data.data.firmware.uid) ? data.data.firmware.uid : '',
                    _asicTypeId: (data.data.firmware.asicType.id) ? data.data.firmware.asicType.id : 0,
                    _customerId: (data.data.firmware.customer.id) ? data.data.firmware.customer.id : 0,
                    _publicKey: (data.data.firmware.publicKey) ? data.data.firmware.publicKey : '',
                    _privateKey: (data.data.firmware.privateKey) ? data.data.firmware.privateKey : '',
                    _maxAsics: (data.data.firmware.maxAsics) ? data.data.firmware.maxAsics : 0,
                    _devPools: (data.data.firmware.devPools) ? data.data.firmware.devPools : '',
                    _devQuotas: (data.data.firmware.devQuotas) ? data.data.firmware.devQuotas : '',
                    _ipRange: (data.data.firmware.ipRange) ? data.data.firmware.ipRange : '',
                    _feeRes: (data.data.firmware.feeRes) ? data.data.firmware.feeRes : 0,
                    _feeMan: (data.data.firmware.feeMan) ? data.data.firmware.feeMan : 0,
                    _feeDev: (data.data.firmware.feeDev) ? data.data.firmware.feeDev : 0,
                }, ()=>{
                    $('#modalEditFirmware').modal('show');
                });
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });        
    }

    
    apiEditFirmware(){
        editFirmware(this.state._id, {
            uid: this.state._uid,
            asicTypeId: this.state._asicTypeId,
            customerId: this.state._customerId,
            publicKey: this.state._publicKey,
            privateKey: this.state._privateKey,
            maxAsics: this.state._maxAsics,
            devPools: this.state._devPools,
            devQuotas: this.state._devQuotas,
            ipRange: this.state._ipRange,
            feeRes: this.state._feeRes,
            feeMan: this.state._feeMan,
            feeDev: this.state._feeDev,
        })
        .then((data)=>{
            if ((data.status === 200)&&(!data.data.error)){
                $('#modalEditFirmware').modal('hide');
                this.toast('Success', 'Firmware Saved');
                this.apiGetFirmwares();
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    modalDelFirmware(i){
        let _id = this.state.firmwares[i].id;
        this.setState({
            _id
        }, ()=>{
            $('#modalDelFirmware').modal('show');
        });
    }

    
    apiDelFirmware(){
        delFirmware(this.state._id)
        .then((data)=>{
            if ((data.status === 200)&&(!data.data.error)){
                $('#modalDelFirmware').modal('hide');
                this.toast('Success', 'Deleted');
                this.apiGetFirmwares();
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }

        
    render() {
        return (
            (!this.state.user)
            ?<div className="app"></div>
            :<div className="app">
                <Sidebar history={this.props.history} user={this.state.user} customer={this.state.customer} customerId={this.state.customerId}/>
                <div className="app-content">
                    <div className="page page-firmwares">
                        <div className="toasts">
                            {(this.state.toasts) &&
                                this.state.toasts.map((toast, i)=>{
                                    return <Toast key={i} id={'toast_'+i} title={toast.title} content={toast.content} />
                                })
                            }
                        </div>
                        <div className="row">
                            <div className="col">
                                <h1 className="page-title">Firmware
                                </h1>
                            </div>
                            <div className="col text-right">
                                {(this.state.user.userType.permissions.find((el) => el.id === 7)) &&
                                    <button className="btn btn-primary"
                                    onClick={this.modalAddFirmware.bind(this)}
                                    >New Firmware</button>
                                }
                            </div>
                        </div>             
                        <div className="row mt-4">
                            <div className="col-lg-12">
                                <Filter 
                                    filter={this.state.filter}
                                    changeFilter={this.changeFilter.bind(this)}
                                    customers={(this.state.user.userType.permissions.find((el) => el.id === 58)) && this.state.customers}                                    
                                    asictypes={this.state.asictypes}
                                />
                            </div>                                                  
                        </div>   
                        <div className="row">
                            <div className="col-lg-12">
                                <FirmwareTable 
                                    user={this.state.user}
                                    firmwares={this.state.firmwares}
                                    editFunction={this.modalEditFirmware.bind(this)}
                                    deleteFunction={this.modalDelFirmware.bind(this)}                                    
                                />
                            </div>
                        </div>                
                    </div>
                </div>
                <ModalFW
                    id="modalAddFirmware"
                    title="Add Firmware"
                    changeState={this.changeState.bind(this)}
                    saveFunction={this.apiAddFirmware.bind(this)}
                    customers={this.state.customers}
                    asictypes={this.state.asictypes}
                    _uid={this.state._uid}
                    _name={this.state._name}
                    _asicTypeId={this.state._asicTypeId}
                    _customerId={this.state._customerId}
                    _publicKey={this.state._publicKey}
                    _privateKey={this.state._privateKey}
                    _maxAsics={this.state._maxAsics}
                    _devPools={this.state._devPools}
                    _devQuotas={this.state._devQuotas}
                    _ipRange={this.state._ipRange}
                    _feeRes={this.state._feeRes}
                    _feeMan={this.state._feeMan}
                    _feeDev={this.state._feeDev}
                    user={this.state.user}
                />
                <ModalFW
                    id="modalEditFirmware"
                    title="Edit Firmware"
                    changeState={this.changeState.bind(this)}
                    saveFunction={this.apiEditFirmware.bind(this)}
                    customers={this.state.customers}
                    asictypes={this.state.asictypes}
                    _uid={this.state._uid}
                    _name={this.state._name}
                    _asicTypeId={this.state._asicTypeId}
                    _customerId={this.state._customerId}
                    _publicKey={this.state._publicKey}
                    _privateKey={this.state._privateKey}
                    _maxAsics={this.state._maxAsics}
                    _devPools={this.state._devPools}
                    _devQuotas={this.state._devQuotas}
                    _ipRange={this.state._ipRange}                    
                    _feeRes={this.state._feeRes}
                    _feeMan={this.state._feeMan}
                    _feeDev={this.state._feeDev}
                    user={this.state.user}
                />
                <Modal 
                    id="modalDelFirmware"
                    title="Delete Firmware"
                    content="Are you sure?"
                    confirmText="Delete"
                    confirmFunction={this.apiDelFirmware.bind(this)}
                />                
            </div>        
        );
    }
}