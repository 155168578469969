import React from 'react';

export default class ModalAsic extends React.Component {

    changeState(state, e){
        this.props.changeState(state, e.target.value);
    }

    render() {

        return (
            <div id={this.props.id} className="modal fade" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">{this.props.title}</h3>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="form-group col-12">
                                    <label>Config</label>
                                    <select className="custom-select" value={this.props._configId}
                                        onChange={this.changeState.bind(this, '_configId')}>
                                        {(this.props._configs)&&(this.props._configs.map((config, i)=>{
                                            return <option key={i} value={config.id}>{config.firmware.asicType.model} - {config.firmware.name} - {config.name} - {config.description}</option>
                                        }))}                                        
                                    </select>
                                </div>                                
                            </div>
                            <div className="row">
                                <div className="form-group col-12">
                                    <label>Pools</label>
                                    <select className="custom-select" value={this.props._poolsetId}
                                        onChange={this.changeState.bind(this, '_poolsetId')}>
                                            <option key={0} value={0}>Not set (local settings)</option>
                                        {(this.props._poolsets)&&(this.props._poolsets.map((poolset, i)=>{
                                            return <option key={i} value={poolset.id}>{poolset.customer.company} - {poolset.name}</option>
                                        }))}                                        
                                    </select>
                                </div>                                
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="action btn btn-primary"
                                onClick={this.props.saveFunction}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}