import React from 'react';

export default class ModalConfig extends React.Component {

    changeState(state, e){
        this.props.changeState(state, e.target.value);
    }

    render() {

        return (
            <div id={this.props.id} className="modal fade" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">{this.props.title}</h3>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                    
                                <div className="form-group col-12">
                                    <label>Name</label>
                                    <input className="form-control" type="test" value={this.props._name}
                                    onChange={this.changeState.bind(this, '_name')} autoComplete="new-name" />
                                </div>
                                <div className="form-group col-12">
                                    <label>Description</label>
                                    <input className="form-control" type="test" value={this.props._description}
                                    onChange={this.changeState.bind(this, '_description')} autoComplete="new-description" />
                                </div>
                                <div className="form-group col-12">
                                    <label>Firmware</label>
                                    <select className="custom-select" value={this.props._firmwareId}
                                        onChange={this.changeState.bind(this, '_firmwareId')}>
                                        <option value={0}></option>
                                        {(this.props.firmwares)&&(this.props.firmwares.map((firmware, i)=>{
                                            return <option key={i} value={firmware.id}>{firmware.customer.company} - {firmware.asicType.model}</option>
                                        }))}                                        
                                    </select>
                                </div>

                                <div className="divider"><span>Miner Settings</span></div>
                                <div className="form-group col-12">
                                    <label>Config</label>
                                    <textarea className="form-control" value={this.props._config}
                                    onChange={this.changeState.bind(this, '_config')} />
                                </div>
                            
                            
                                <div className="form-group col-12">
                                    <label>Preset</label>
                                    <textarea className="form-control" value={this.props._preset}
                                    onChange={this.changeState.bind(this, '_preset')} />
                                </div>
                            
                            </div>
                            
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="action btn btn-primary"
                                onClick={this.props.saveFunction}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}