import React from 'react';
import {switchApi, authCheck, getManagers, getResellers, getCustomers, getCustomer, addCustomer, editCustomer, delCustomer} from '../Api';
import Modal from '../components/Modal';
import ModalCustomer from '../components/ModalCustomer';
import $ from 'jquery';
import Toast from '../components/Toast';
import Sidebar from '../components/Sidebar';
import CustomerTable from '../components/CustomerTable';

export default class PageCustomers extends React.Component {

    state = {
        toasts: [],
        customers: [],
        customer: {},
        _id: 0,
        _company: '',
        _address: '',
        _description: '',
        _phone: '',
        _email: '',
        _firstName: '',
        _lastName: '',
        _password: '',
        _managerId: 0,
        _resellerId: 0,
    }

    toast(title, content){
        let toasts = this.state.toasts;
        let i = toasts.push({
            title: title,
            content: content
        });
        this.setState({toasts},()=>{
            $('#toast_'+(i-1)).toast({delay: 5000});
            $('#toast_'+(i-1)).toast('show');
        });        
    }

    componentDidMount(){
        this.isMount = true;
        this.apiAuthCheck();
    }

    componentWillUnmount(){
        this.isMount = false;
    }

    changeState(state, value){
        if (this.isMount) this.setState({[state]: value});
    }

    
    apiAuthCheck(){
        authCheck()
        .then((data)=>{
            if ((data.status === 200)&&(data.data.user)){
                if (this.isMount) this.setState({user: data.data.user});
                this.apiGetManagers();
                this.apiGetResellers();
                this.apiGetCustomers();
            }else{
                console.log(data);
                this.props.history.push('/');
            }          
        })
        .catch((err)=>{
            console.log(err);
            if (err.code === 'ECONNABORTED') {
                switchApi();
                this.apiAuthCheck();
            }else{
                this.props.history.push('/');
            }
        });
    }

    apiGetManagers(){
        getManagers()
        .then((data)=>{
            if ((data.status === 200)&&(data.data.managers)){
                if (this.isMount) this.setState({managers: data.data.managers});                
            }else{
                console.log(data);
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }

    apiGetResellers(){
        getResellers()
        .then((data)=>{
            if ((data.status === 200)&&(data.data.resellers)){
                if (this.isMount) this.setState({resellers: data.data.resellers});                
            }else{
                console.log(data);
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }

    apiGetCustomers(){
        getCustomers()
        .then((data)=>{
            if ((data.status === 200)&&(data.data.customers)){
                if (this.isMount) this.setState({customers: data.data.customers});                
            }else{
                console.log(data);
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    modalAddCustomer(){
        this.setState({
            _company: '',
            _address: '',
            _description: '',
            _phone: '',
            _email: '',
            _firstName: '',
            _lastName: '',
            _password: '',
            _managerId: 0,
            _resellerId: 0,
        }, ()=>{
            $('#modalAddCustomer').modal('show');
        });
    }


    apiAddCustomer(){
        addCustomer({
            company: this.state._company,
            address: this.state._address,
            description: this.state._description,
            phone: this.state._phone,
            email: this.state._email,
            firstName: this.state._firstName,
            lastName: this.state._lastName,
            password: this.state._password,
            managerId: this.state._managerId,
            resellerId: this.state._resellerId,
        })
        .then((data)=>{
            if ((data.status === 200)&&(!data.data.error)){
                $('#modalAddCustomer').modal('hide');
                this.toast('Success', 'Saved');
                this.apiGetCustomers();
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });     
    }


    modalEditCustomer(i){
        let _id = this.state.customers[i].id;
        getCustomer(_id)
        .then((data)=>{
            if ((data.status === 200)&&(data.data.customer)){
                if (this.isMount) this.setState({
                    _id,
                    _company: (data.data.customer.company) ? data.data.customer.company : '',
                    _address: (data.data.customer.address) ? data.data.customer.address : '',
                    _description: (data.data.customer.description) ? data.data.customer.description : '',
                    _phone: (data.data.customer.phone) ? data.data.customer.phone : '',
                    _email: (data.data.customer.email) ? data.data.customer.email : '',
                    _firstName: (data.data.customer.firstName) ? data.data.customer.firstName : '',
                    _lastName: (data.data.customer.lastName) ? data.data.customer.lastName : '',
                    _managerId: (data.data.customer.manager) ? data.data.customer.manager.id : 0,
                    _resellerId: (data.data.customer.reseller) ? data.data.customer.reseller.id : 0,
                }, ()=>{
                    $('#modalEditCustomer').modal('show');
                });
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    apiEditCustomer(){
        editCustomer(this.state._id, {
            company: this.state._company,
            address: this.state._address,
            description: this.state._description,
            phone: this.state._phone,
            email: this.state._email,
            firstName: this.state._firstName,
            lastName: this.state._lastName,
        })
        .then((data)=>{
            if ((data.status === 200)&&(!data.data.error)){
                $('#modalEditCustomer').modal('hide');
                this.toast('Success', 'Saved');
                this.apiGetCustomers();
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    modalDelCustomer(i){
        let _id = this.state.customers[i].id;
        this.setState({
            _id
        }, ()=>{
            $('#modalDelCustomer').modal('show');
        });
    }


    apiDelCustomer(){
        delCustomer(this.state._id)
        .then((data)=>{
            if ((data.status === 200)&&(!data.data.error)){
                $('#modalDelCustomer').modal('hide');
                this.toast('Success', 'Deleted');
                this.apiGetCustomers();
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    render() {
        return (
            (!this.state.user)
            ?<div className="app"></div>
            :<div className="app">
                <Sidebar history={this.props.history} user={this.state.user} customer={this.state.customer} customerId={this.state.customerId}/>
                <div className="app-content">
                    <div className="page page-users">
                        <div className="toasts">
                            {(this.state.toasts) &&
                                this.state.toasts.map((toast, i)=>{
                                    return <Toast key={i} id={'toast_'+i} title={toast.title} content={toast.content} />
                                })
                            }
                        </div>
                        <div className="row">
                            <div className="col">
                                <h1 className="page-title">Customers
                                </h1>
                            </div>
                            <div className="col text-right">
                                {(this.state.user.userType.permissions.find((el) => el.id === 11)) &&
                                    <button className="btn btn-primary"
                                    onClick={this.modalAddCustomer.bind(this)}
                                    >New Customer</button>
                                }
                            </div>
                        </div>             
                        <div className="row">
                            <div className="col-lg-12 mt-4">
                                <CustomerTable 
                                    user={this.state.user}
                                    customers={this.state.customers}
                                    editFunction={this.modalEditCustomer.bind(this)}
                                    deleteFunction={this.modalDelCustomer.bind(this)}
                                />
                            </div>
                        </div>                
                    </div>
                </div>
                <ModalCustomer
                    id="modalAddCustomer"
                    title="Add Customer"
                    changeState={this.changeState.bind(this)}
                    saveFunction={this.apiAddCustomer.bind(this)}
                    managers = {this.state.managers}
                    resellers = {this.state.resellers}
                    _company = {this.state._company}
                    _address = {this.state._address}
                    _description = {this.state._description}
                    _phone = {this.state._phone}
                    _email = {this.state._email}
                    _firstName = {this.state._firstName}
                    _lastName = {this.state._lastName}
                    _password = {this.state._password}
                    _managerId = {this.state._managerId}
                    _resellerId = {this.state._resellerId}
                />
                <ModalCustomer
                    id="modalEditCustomer"
                    title="Edit Customer"
                    changeState={this.changeState.bind(this)}
                    saveFunction={this.apiEditCustomer.bind(this)}
                    managers = {this.state.managers}
                    resellers = {this.state.resellers}
                    _company = {this.state._company}
                    _address = {this.state._address}
                    _description = {this.state._description}
                    _phone = {this.state._phone}
                    _email = {this.state._email}
                    _firstName = {this.state._firstName}
                    _lastName = {this.state._lastName}
                    _managerId = {this.state._managerId}
                    _resellerId = {this.state._resellerId}
                />
                <Modal 
                    id="modalDelCustomer"
                    title="Delete Customer"
                    content="Are you sure?"
                    confirmText="Delete"
                    confirmFunction={this.apiDelCustomer.bind(this)}
                />    
            </div>        
        );
    }
}