import React from 'react';

export default class ConfigTable extends React.Component {

    state = {
        
    }

    editConfig(i){
        this.props.editFunction(i)
    }

    copyConfig(i){
        this.props.copyFunction(i)
    }

    deleteConfig(i){
        this.props.deleteFunction(i)
    }

    changeState(state, e){
        this.props.changeState(state, e.target.value);
    }

    render() {
        return (
            <div className="component config-table">
                <div className="panel pl-0 pr-0 pt-0 pb-0">
                    <table className="bordered">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Customer</th>
                                <th>Asic Type</th>
                                <th>Name</th>
                                <th>Description</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(this.props.configs) &&
                                this.props.configs.map((config, i)=>{
                                    return <tr key={i}>
                                                <td>{config.id}</td>
                                                <td>{config.firmware.customer.company}</td>
                                                <td>{config.firmware.asicType.model}</td>
                                                <td>{config.name}</td>
                                                <td>{config.description}</td>
                                                <td className="actions">
                                                    {(this.props.user.userType.permissions.find((el) => el.id === 16)) &&
                                                    <div className="dropdown">
                                                        <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">                                                            
                                                        </button>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <button className="dropdown-item"
                                                                onClick={this.editConfig.bind(this, i)}
                                                            >Edit</button>
                                                            <button className="dropdown-item"
                                                                onClick={this.copyConfig.bind(this, i)}
                                                            >Copy</button>
                                                            <button className="dropdown-item" href="#"
                                                                onClick={this.deleteConfig.bind(this, i)}
                                                            >Delete</button>
                                                        </div>
                                                    </div>
                                                    }
                                                </td>
                                            </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}