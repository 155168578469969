import React from 'react';

export default class UserTable extends React.Component {

    state = {
        
    }

    editUser(id){
        this.props.editFunction(id)
    }

    resetPassword(id){
        this.props.resetPassFunction(id)
    }

    delUser(id){
        this.props.delFunction(id)
    }

    render() {
        return (
            <div className="component user-table">
                <div className="panel pl-0 pr-0 pt-0 pb-0">
                    <table className="bordered">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Email</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Account Type</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(this.props.users) &&
                                this.props.users.map((user, i)=>{
                                    return <tr key={i}>
                                                <td>{user.id}</td>
                                                <td>{user.email}</td>
                                                <td>{user.firstName}</td>
                                                <td>{user.lastName}</td>
                                                <td>{user.userType.name}</td>
                                                <td>{user.status}</td>
                                                <td className="actions">
                                                    <div className="dropdown">
                                                        <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">                                                            
                                                        </button>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <button className="dropdown-item"
                                                                onClick={this.editUser.bind(this, i)}
                                                            >Edit Account</button>
                                                            <button className="dropdown-item" href="#"
                                                                onClick={this.resetPassword.bind(this, i)}
                                                            >Reset Password</button>
                                                            <button className="dropdown-item" href="#"
                                                                onClick={this.delUser.bind(this, i)}
                                                            >Delete Account</button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}