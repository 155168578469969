import React from 'react';
import {switchApi, authCheck, getCustomers, getPoolsets, getPoolset, addPoolset, editPoolset, delPoolset } from '../Api';
import Modal from '../components/Modal';
import ModalPoolset from '../components/ModalPoolset';
import $ from 'jquery';
import Toast from '../components/Toast';
import Filter from '../components/Filter';
import Sidebar from '../components/Sidebar';
import PoolsetTable from '../components/PoolsetTable';

export default class PagePoolsets extends React.Component {

    state = {
        toasts: [],
        filter: {
            customerId: '',
        },
        customers: [],
        poolsets: [],
        poolset: {},
        _id: 0,
        _name: '',
        _pool1Url: '',
        _pool1User: '',
        _pool1Pass: '',
        _pool2Url: '',
        _pool2User: '',
        _pool2Pass: '',
        _pool3Url: '',
        _pool3User: '',
        _pool3Pass: '',
        _customerId: 0
    }


    toast(title, content){
        let toasts = this.state.toasts;
        let i = toasts.push({
            title: title,
            content: content
        });
        this.setState({toasts},()=>{
            $('#toast_'+(i-1)).toast({delay: 5000});
            $('#toast_'+(i-1)).toast('show');
        });        
    }


    changeFilter(name, value){
        let filter = this.state.filter;
        filter[name] = value;
        
        this.setState({filter},()=>{
            this.apiAuthCheck();
        });
    }


    componentDidMount(){
        this.isMount = true;
        this.apiAuthCheck();
    }


    componentWillUnmount(){
        this.isMount = false;
    }


    changeState(state, value){
        if (this.isMount) this.setState({[state]: value});
    }


    apiAuthCheck(){
        authCheck()
        .then((data)=>{
            if ((data.status === 200)&&(data.data.user)){
                if (this.isMount) this.setState({user: data.data.user});
                this.apiGetCustomers();
                this.apiGetPoolsets();
            }else{
                console.log(data);
                this.props.history.push('/');
            }          
        })
        .catch((err)=>{
            console.log(err);
            if (err.code === 'ECONNABORTED') {
                switchApi();
                this.apiAuthCheck();
            }else{
                this.props.history.push('/');
            }
        });
    }


    apiGetCustomers(){
        getCustomers()
        .then((data)=>{
            if ((data.status === 200)&&(data.data.customers)){
                if (this.isMount) this.setState({customers: data.data.customers});                
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    apiGetPoolsets(){
        getPoolsets('?customerId='+this.state.filter.customerId)
        .then((data)=>{
            if ((data.status === 200)&&(data.data.poolsets)){
                if (this.isMount) this.setState({poolsets: data.data.poolsets});
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    modalAddPoolset(){
        this.setState({
            _name: '',
            _pool1Url: '',
            _pool1User: '',
            _pool1Pass: '',
            _pool2Url: '',
            _pool2User: '',
            _pool2Pass: '',
            _pool3Url: '',
            _pool3User: '',
            _pool3Pass: '',
            _customerId: 0
        }, ()=>{
            $('#modalAddPoolset').modal('show');
        });
    }


    apiAddPoolset(){
        addPoolset({
            name: this.state._name,
            pool1Url: this.state._pool1Url,
            pool1User: this.state._pool1User,
            pool1Pass: this.state._pool1Pass,
            pool2Url: this.state._pool2Url,
            pool2User: this.state._pool2User,
            pool2Pass: this.state._pool2Pass,
            pool3Url: this.state._pool3Url,
            pool3User: this.state._pool3User,
            pool3Pass: this.state._pool3Pass,
            customerId: this.state._customerId,
        })
        .then((data)=>{
            if ((data.status === 200)&&(!data.data.error)){
                $('#modalAddPoolset').modal('hide');
                this.toast('Success', 'Saved');
                this.apiGetPoolsets();
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    modalEditPoolset(i){
        let _id = this.state.poolsets[i].id;
        getPoolset(_id)
        .then((data)=>{
            if ((data.status === 200)&&(data.data.poolset)){
                if (this.isMount) this.setState({
                    _id,
                    _name: (data.data.poolset.name) ? data.data.poolset.name : '',
                    _pool1Url: (data.data.poolset.pool1Url) ? data.data.poolset.pool1Url : '',
                    _pool1User: (data.data.poolset.pool1User) ? data.data.poolset.pool1User : '',
                    _pool1Pass: (data.data.poolset.pool1Pass) ? data.data.poolset.pool1Pass : '',
                    _pool2Url: (data.data.poolset.pool2Url) ? data.data.poolset.pool2Url : '',
                    _pool2User: (data.data.poolset.pool2User) ? data.data.poolset.pool2User : '',
                    _pool2Pass: (data.data.poolset.pool2Pass) ? data.data.poolset.pool2Pass : '',
                    _pool3Url: (data.data.poolset.pool3Url) ? data.data.poolset.pool3Url : '',
                    _pool3User: (data.data.poolset.pool3User) ? data.data.poolset.pool3User : '',
                    _pool3Pass: (data.data.poolset.pool3Pass) ? data.data.poolset.pool3Pass : '',
                    _customerId: (data.data.poolset.customer.id) ? data.data.poolset.customer.id : 0,
                }, ()=>{
                    $('#modalEditPoolset').modal('show');
                });
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });        
    }

    
    apiEditPoolset(){
        editPoolset(this.state._id, {
          name: this.state._name,
          pool1Url: this.state._pool1Url,
          pool1User: this.state._pool1User,
          pool1Pass: this.state._pool1Pass,
          pool2Url: this.state._pool2Url,
          pool2User: this.state._pool2User,
          pool2Pass: this.state._pool2Pass,
          pool3Url: this.state._pool3Url,
          pool3User: this.state._pool3User,
          pool3Pass: this.state._pool3Pass,
          customerId: this.state._customerId,
        })
        .then((data)=>{
            if ((data.status === 200)&&(!data.data.error)){
                $('#modalEditPoolset').modal('hide');
                this.toast('Success', 'Saved');
                this.apiGetPoolsets();
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    modalDelPoolset(i){
        let _id = this.state.poolsets[i].id;
        this.setState({
            _id
        }, ()=>{
            $('#modalDelPoolset').modal('show');
        });
    }

    
    apiDelPoolset(){
        delPoolset(this.state._id)
        .then((data)=>{
            if ((data.status === 200)&&(!data.data.error)){
                $('#modalDelPoolset').modal('hide');
                this.toast('Success', 'Deleted');
                this.apiGetPoolsets();
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }

        
    render() {
        return (
            (!this.state.user)
            ?<div className="app"></div>
            :<div className="app">
                <Sidebar history={this.props.history} user={this.state.user} customer={this.state.customer} customerId={this.state.customerId}/>
                <div className="app-content">
                    <div className="page page-firmwares">
                        <div className="toasts">
                            {(this.state.toasts) &&
                                this.state.toasts.map((toast, i)=>{
                                    return <Toast key={i} id={'toast_'+i} title={toast.title} content={toast.content} />
                                })
                            }
                        </div>
                        <div className="row">
                            <div className="col">
                                <h1 className="page-title">Pool Sets
                                </h1>
                            </div>
                            <div className="col text-right">
                                {(this.state.user.userType.permissions.find((el) => el.id === 68)) &&
                                    <button className="btn btn-primary"
                                    onClick={this.modalAddPoolset.bind(this)}
                                    >New Pool Set</button>
                                }
                            </div>
                        </div>             
                        <div className="row mt-4">
                            <div className="col-lg-12">
                                <Filter 
                                    filter={this.state.filter}
                                    changeFilter={this.changeFilter.bind(this)}
                                    customers={(this.state.user.userType.permissions.find((el) => el.id === 58)) && this.state.customers}
                                />
                            </div>                                                  
                        </div>   
                        <div className="row">
                            <div className="col-lg-12">
                                <PoolsetTable 
                                    user={this.state.user}
                                    poolsets={this.state.poolsets}
                                    editFunction={this.modalEditPoolset.bind(this)}
                                    deleteFunction={this.modalDelPoolset.bind(this)}
                                />
                            </div>
                        </div>                
                    </div>
                </div>
                <ModalPoolset
                    id="modalAddPoolset"
                    title="Add Pool Set"
                    changeState={this.changeState.bind(this)}
                    saveFunction={this.apiAddPoolset.bind(this)}
                    customers={this.state.customers}
                    _name={this.state._name}
                    _pool1Url={this.state._pool1Url}
                    _pool1User={this.state._pool1User}
                    _pool1Pass={this.state._pool1Pass}
                    _pool2Url={this.state._pool2Url}
                    _pool2User={this.state._pool2User}
                    _pool2Pass={this.state._pool2Pass}
                    _pool3Url={this.state._pool3Url}
                    _pool3User={this.state._pool3User}
                    _pool3Pass={this.state._pool3Pass}
                    _customerId={this.state._customerId}                    
                    user={this.state.user}
                />
                <ModalPoolset
                    id="modalEditPoolset"
                    title="Edit Pool Set"
                    changeState={this.changeState.bind(this)}
                    saveFunction={this.apiEditPoolset.bind(this)}
                    customers={this.state.customers}
                    _name={this.state._name}
                    _pool1Url={this.state._pool1Url}
                    _pool1User={this.state._pool1User}
                    _pool1Pass={this.state._pool1Pass}
                    _pool2Url={this.state._pool2Url}
                    _pool2User={this.state._pool2User}
                    _pool2Pass={this.state._pool2Pass}
                    _pool3Url={this.state._pool3Url}
                    _pool3User={this.state._pool3User}
                    _pool3Pass={this.state._pool3Pass}
                    _customerId={this.state._customerId}
                    user={this.state.user}
                />
                <Modal 
                    id="modalDelPoolset"
                    title="Delete Pool Set"
                    content="Are you sure?"
                    confirmText="Delete"
                    confirmFunction={this.apiDelPoolset.bind(this)}
                />                
            </div>        
        );
    }
}