import axios from 'axios';
import pkg from '../package.json';

let url = process.env.REACT_APP_API_URL;

// request interceptor
axios.interceptors.request.use(function (config) {
    let token = 'Bearer ' + localStorage.getItem('ASICWORLDJWT');
	config.headers = { Authorization: `${token}`, 'X-Version': `${pkg.version}`};
	config.timeout = 10000;
	return config;	
});


export const switchApi = () => {
	if (url === process.env.REACT_APP_API_URL) {
		url = process.env.REACT_APP_API_URL2;
	}else{
		url = process.env.REACT_APP_API_URL;
	}
}

export const authCheck = () => {
	//if (!localStorage.getItem('ASICWORLDJWT')) return new Promise((resolve)=>{return resolve({data:{error: 'Auth token is not supplied'}})});
	//axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('ASICWORLDJWT');
	return axios.get(url+'/auth/check');
};

export const authLogin = (data) => {
	return axios.post(url+'/auth/login', data);
};

export const authLogout = () => {
	return axios.get(url+'/auth/logout');
};

export const authRegister = (data) => {
	return axios.post(url+'/auth/register', data);
};

export const getAsicTypes = (filter='') => {
	return axios.get(url+'/asictypes'+filter);
};

export const getAsicType = (id) => {
	return axios.get(url+'/asictypes/'+id);
};

export const addAsicType = (data) => {
	return axios.post(url+'/asictypes', data);
};

export const editAsicType = (id, data) => {
	return axios.put(url+'/asictypes/'+id, data);
};

export const delAsicType = (id) => {
	return axios.delete(url+'/asictypes/'+id);
};


export const getUsers = (filter='') => {
	return axios.get(url+'/users'+filter);
};

export const getUser = (id) => {
	return axios.get(url+'/users/'+id);
};

export const editUser = (id, data) => {
	return axios.put(url+'/users/'+id, data);
};

export const delUser = (id) => {
	return axios.delete(url+'/users/'+id);
};

export const resetPass = (id, data) => {
	return axios.put(url+'/users/'+id+'/reset', data);
};


export const getUserTypes = () => {
	return axios.get(url+'/usertypes');
};


export const getCustomers = () => {
	return axios.get(url+'/customers');
};

export const getCustomer = (id) => {
	return axios.get(url+'/customers/'+id);
};

export const addCustomer = (data) => {
	return axios.post(url+'/customers/', data);
};

export const editCustomer = (id, data) => {
	return axios.put(url+'/customers/'+id, data);
};

export const delCustomer = (id) => {
	return axios.delete(url+'/customers/'+id);
};


export const getManagers = () => {
	return axios.get(url+'/managers');
};

export const getManager = (id) => {
	return axios.get(url+'/managers/'+id);
};

export const addManager = (data) => {
	return axios.post(url+'/managers/', data);
};

export const editManager = (id, data) => {
	return axios.put(url+'/managers/'+id, data);
};

export const delManager = (id) => {
	return axios.delete(url+'/managers/'+id);
};


export const getResellers = () => {
	return axios.get(url+'/resellers');
};

export const getReseller = (id) => {
	return axios.get(url+'/resellers/'+id);
};

export const addReseller = (data) => {
	return axios.post(url+'/resellers/', data);
};

export const editReseller = (id, data) => {
	return axios.put(url+'/resellers/'+id, data);
};

export const delReseller = (id) => {
	return axios.delete(url+'/resellers/'+id);
};


export const getFirmwares = (filter='') => {
	return axios.get(url+'/firmwares'+filter);
};

export const getFirmware = (id) => {
	return axios.get(url+'/firmwares/'+id);
};

export const addFirmware = (data) => {
	return axios.post(url+'/firmwares', data);
};

export const editFirmware = (id, data) => {
	return axios.put(url+'/firmwares/'+id, data);
};

export const delFirmware = (id) => {
	return axios.delete(url+'/firmwares/'+id);
};


export const getConfigs = (filter='') => {
	return axios.get(url+'/configs'+filter);
};

export const getConfig = (id) => {
	return axios.get(url+'/configs/'+id);
};

export const addConfig = (data) => {
	return axios.post(url+'/configs', data);
};

export const editConfig = (id, data) => {
	return axios.put(url+'/configs/'+id, data);
};

export const delConfig = (id) => {
	return axios.delete(url+'/configs/'+id);
};


export const getPoolsets = (filter='') => {
	return axios.get(url+'/poolsets'+filter);
};

export const getPoolset = (id) => {
	return axios.get(url+'/poolsets/'+id);
};

export const addPoolset = (data) => {
	return axios.post(url+'/poolsets', data);
};

export const editPoolset = (id, data) => {
	return axios.put(url+'/poolsets/'+id, data);
};

export const delPoolset = (id) => {
	return axios.delete(url+'/poolsets/'+id);
};


export const getAsics = (filter='') => {
	return axios.get(url+'/asics'+filter);
};

export const getAsic = (id) => {
	return axios.get(url+'/asics/'+id);
};


export const editAsic = (id, data) => {
	return axios.put(url+'/asics/'+id, data);
};


export const getStats = (filter='') => {
	return axios.get(url+'/stats'+filter);
};

export const getChartData = (filter='') => {
	return axios.get(url+'/stats/chart-data'+filter);
};

export const getBP = () => {
	return axios.get(url+'/asics/ports');
};


export const jobRestartMiner = (asicId) => {
	return axios.post(url+'/jobs/'+ asicId + '/restart_miner');
};

export const jobStopMiner = (asicId) => {
	return axios.post(url+'/jobs/'+ asicId + '/stop_miner');
};

export const jobRebootSystem = (asicId) => {
	return axios.post(url+'/jobs/'+ asicId + '/reboot_system');
};

export const jobUninstall = (asicId) => {
	return axios.post(url+'/jobs/'+ asicId + '/uninstall');
};

export const jobRSO = (asicId, port) => {
	return axios.post(url+'/jobs/'+ asicId + '/rso/'+port);
};

export const jobRSC = (asicId) => {
	return axios.post(url+'/jobs/'+ asicId + '/rsc');
};

export const jobReinstall = (asicId) => {
	return axios.post(url+'/jobs/'+ asicId + '/reinstall');
};

export const jobCancel = (asicId) => {
	return axios.delete(url+'/jobs/'+ asicId);
};

export const jobFanAdjust = (asicId, data) => {
	return axios.post(url+'/jobs/'+ asicId + '/fan_adjust', data);
};


