import React from 'react';

export default class ModalFan extends React.Component {

    changeState(state, e){
        this.props.changeState(state, e.target.value);
    }

    render() {

        return (
            <div id={this.props.id} className="modal fade" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">{this.props.title}</h3>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="form-group col-12">
                                    <label>Adjust Temp</label>
                                    <input className="form-control" type="text" value={this.props._adjust_temp}
                                    onChange={this.changeState.bind(this, '_adjust_temp')} autoComplete="new-adjust-temp"/>
                                </div>                                
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="action btn btn-primary"
                                onClick={this.props.saveFunction}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}