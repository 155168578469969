import React from 'react';
import {switchApi, authCheck, authLogin} from '../Api';
import $ from 'jquery';
import Toast from '../components/Toast';

export default class PageLogin extends React.Component {

    state = {
        email: '',
        password: '',
        toasts: []
    }

    toast(title, content){
        let toasts = this.state.toasts;
        let i = toasts.push({
            title: title,
            content: content
        });
        this.setState({toasts},()=>{
            $('#toast_'+(i-1)).toast({delay: 5000});
            $('#toast_'+(i-1)).toast('show');
        });        
    }

    componentDidMount(){
        this.isMount = true;
        this.apiAuthCheck();
    }

    componentWillUnmount(){
        this.isMount = false;
    }

    changeState(state, e){
        if (this.isMount) this.setState({[state]: e.target.value});
    }

    apiAuthCheck(){
        authCheck()
        .then((data)=>{
            if ((data.status === 200) && (data.data.user)){
                this.props.history.push('/overview');
            }else{
                console.log(data);
                //this.toast('Error', JSON.stringify(res.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            if (err.code === 'ECONNABORTED') {
                switchApi();
                this.apiAuthCheck();
            }
        });
    }
    
    apiAuthLogin(){
        authLogin({
            email: this.state.email,
            password: this.state.password
        })
        .then((data)=>{
            if ((data.status === 200) && (data.data.user)){
                localStorage.setItem('ASICWORLDJWT', data.data.token);
                this.props.history.push('/overview');
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            } 
        })
        .catch((err)=>{
            console.log(err);
            if (err.code === 'ECONNABORTED') {
                switchApi();
                this.apiAuthLogin();
            }
        });
    }

    render() {
        return (
            <div className="app">
                <div className="app-content">
                    <div className="page page-form page-login">
                        <div className="toasts">
                            {(this.state.toasts) &&
                                this.state.toasts.map((toast, i)=>{
                                    return <Toast key={i} id={'toast_'+i} title={toast.title} content={toast.content} />
                                })
                            }
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="logo mb-5"><a href="/" >&nbsp;</a></div>
                                <h1 className="page-title">Login</h1>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input className="form-control" type="email" value={this.state.email} autoFocus 
                                    onChange={this.changeState.bind(this, 'email')} 
                                    onKeyPress={(e)=>{if (e.key === "Enter") this.apiAuthLogin()}}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Password</label>
                                    <input className="form-control" type="password" value={this.state.password}
                                    onChange={this.changeState.bind(this, 'password')} 
                                    onKeyPress={(e)=>{if (e.key === "Enter") this.apiAuthLogin()}}
                                    />
                                </div>
                                <div className="form-group page-form-actions mt-4">
                                    <div>
                                        {/* <a href="/register"
                                            onClick={(e)=>{e.preventDefault(); this.props.history.push('/register');}}>
                                            New customer
                                        </a><br/>
                                        <a href="/forgot"
                                            onClick={(e)=>{e.preventDefault(); this.props.history.push('/forgot');}}>
                                            Forgot password
                                        </a> */}
                                    </div>
                                    <button className="btn btn-primary"
                                        onClick={this.apiAuthLogin.bind(this)}
                                    >Login</button>
                                </div>    
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        );
    }
}