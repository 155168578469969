import React from 'react';
import ReactHighcharts from 'react-highcharts';

export default class OverviewCharts extends React.Component {

    render() {
        return (
            <div className="component overview-chart">
                <div className="panel-title">
                </div>                
                <div className="panel pl-0 pr-0 pt-0 pb-0 panel-chart">
                    <ReactHighcharts 
                        config = {this.props.configHighcharts}
                    ></ReactHighcharts>
                </div>
            </div>
        );
    }
}