import React from 'react';

export default class ModalReseller extends React.Component {

    changeState(state, e){
        this.props.changeState(state, e.target.value);
    }

    render() {

        return (
            <div id={this.props.id} className="modal fade" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">{this.props.title}</h3>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                {(this.props.id === 'modalAddReseller')
                                ?   <div className="form-group col-12">
                                        <label>Parent Manager</label>
                                        <select className="custom-select" value={this.props._managerId}
                                        onChange={this.changeState.bind(this, '_managerId')}>
                                            <option value={0}></option>
                                            {(this.props.managers)&&(this.props.managers.map((manager, i)=>{
                                                return <option key={i} value={manager.id}>{manager.company}</option>
                                            }))}                                        
                                        </select>
                                    </div>
                                :   <div className="form-group col-12">
                                        <label>Parent Manager</label>
                                        <select className="custom-select" defaultValue={this.props._managerId}>
                                            {(this.props.managers)&&(this.props.managers.map((manager, i)=>{
                                                if (this.props._managerId === manager.id)
                                                return <option key={i} value={manager.id}>{manager.company}</option>
                                            }))}                                        
                                        </select>
                                    </div>
                                }
                                <div className="form-group col-12">
                                    <label>Company</label>
                                    <input className="form-control" type="text" value={this.props._company}
                                    onChange={this.changeState.bind(this, '_company')} autoComplete="new-company"/>
                                </div>
                                <div className="form-group col-12">
                                    <label>Address</label>
                                    <input className="form-control" type="text" value={this.props._address}
                                    onChange={this.changeState.bind(this, '_address')} autoComplete="new-address"/>
                                </div>
                                <div className="form-group col-12">
                                    <label>Description</label>
                                    <input className="form-control" type="text" value={this.props._description}
                                    onChange={this.changeState.bind(this, '_description')} autoComplete="new-description"/>
                                </div>
                                
                                <div className="divider"><span>Contact Info</span></div>
                                <div className="form-group col-12">
                                    <label>Email</label>
                                    <input className="form-control" type="text" value={this.props._email}
                                    onChange={this.changeState.bind(this, '_email')} autoComplete="new-email"/>
                                </div>
                                <div className="form-group col-12">
                                    <label>Name</label>
                                    <input className="form-control" type="text" value={this.props._firstName}
                                    onChange={this.changeState.bind(this, '_firstName')} autoComplete="new-firstName"/>
                                </div>
                                <div className="form-group col-12">
                                    <label>Lastname</label>
                                    <input className="form-control" type="text" value={this.props._lastName}
                                    onChange={this.changeState.bind(this, '_lastName')} autoComplete="new-lastName"/>
                                </div>
                                <div className="form-group col-12">
                                    <label>Phone</label>
                                    <input className="form-control" type="text" value={this.props._phone}
                                    onChange={this.changeState.bind(this, '_phone')} />
                                </div>
                                <div className="divider"><span>Fee Pool</span></div>
                                <div className="form-group col-12">
                                    <label>Pool URL</label>
                                    <input className="form-control" type="text" value={this.props._poolUrl}
                                    onChange={this.changeState.bind(this, '_poolUrl')} />
                                </div>
                                <div className="form-group col-6 pr-2">
                                    <label>Pool User</label>
                                    <input className="form-control" type="text" value={this.props._poolUser}
                                    onChange={this.changeState.bind(this, '_poolUser')} />
                                </div>
                                <div className="form-group col-6 pl-2">
                                    <label>Pool Pass</label>
                                    <input className="form-control" type="text" value={this.props._poolPass}
                                    onChange={this.changeState.bind(this, '_poolPass')} />
                                </div>
                                                                
                                {(this.props.id === 'modalAddReseller') &&
                                    <>
                                        <div className="divider"><span>Create Account</span></div>
                                        <div className="form-group col-12">
                                            <label>Password</label>
                                            <input className="form-control" type="password" value={this.props._password}
                                            onChange={this.changeState.bind(this, '_password')} autoComplete="new-password"/>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="action btn btn-primary"
                                onClick={this.props.saveFunction}
                                >Save</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}