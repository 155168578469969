import React from 'react';
import {switchApi, authCheck, getCustomers, getConfigs, getFirmwares, getAsics, getStats } from '../Api';
import $ from 'jquery';
import Toast from '../components/Toast';
import Filter from '../components/Filter';
import Sidebar from '../components/Sidebar';
import StatsTable from '../components/StatsTable';


export default class PageStats extends React.Component {

    state = {
        toasts: [],
        filter: {
            customerId: '',
            firmwareId: '',
            configId: '',
            asicId: ''
        },
        interval: 60000,
        stats: [],        
    }

    
    toast(title, content){
        let toasts = this.state.toasts;
        let i = toasts.push({
            title: title,
            content: content
        });
        this.setState({toasts},()=>{
            $('#toast_'+(i-1)).toast({delay: 5000});
            $('#toast_'+(i-1)).toast('show');
        });        
    }

    changeFilter(name, value){
        let filter = this.state.filter;
        filter[name] = value;
        
        if (name === 'customerId') { filter.firmwareId=''; filter.configId=''; filter.asicId=''; }
        if (name === 'firmwareId') { filter.configId=''; filter.asicId=''; }
        if (name === 'configId') { filter.asicId=''; }

        this.setState({filter},()=>{
            this.apiAuthCheck();
        });
    }

    componentDidMount(){
        this.isMount = true;
        this.apiAuthCheck();
        this.intervalGetStats = setInterval(this.apiGetStats.bind(this), this.state.interval);
    }


    componentWillUnmount(){
        this.isMount = false;
        clearInterval(this.intervalGetStats);
    }


    changeState(state, value){
        if (this.isMount) this.setState({[state]: value});
    }


    apiAuthCheck(){
        authCheck()
        .then((data)=>{
            if ((data.status === 200)&&(data.data.user)){
                if (this.isMount) this.setState({user: data.data.user});
                this.apiGetCustomers();
                this.apiGetFirmwares();
                this.apiGetConfigs();
                this.apiGetAsics();
                this.apiGetStats();
            }else{
                console.log(data);
                this.props.history.push('/');
            }          
        })
        .catch((err)=>{
            console.log(err);
            if (err.code === 'ECONNABORTED') {
                switchApi();
                this.apiAuthCheck();
            }else{
                this.props.history.push('/');
            }
        });
    }


    apiGetCustomers(){
        getCustomers()
        .then((data)=>{
            if ((data.status === 200)&&(data.data.customers)){
                if (this.isMount) this.setState({customers: data.data.customers});                
            }else{
                console.log(data);
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }

    
    apiGetFirmwares(){
        getFirmwares('?customerId='+this.state.filter.customerId)
        .then((data)=>{
            if ((data.status === 200)&&(data.data.firmwares)){
                if (this.isMount) this.setState({firmwares: data.data.firmwares});
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    apiGetConfigs(){
        getConfigs('?customerId='+this.state.filter.customerId+'&firmwareId='+this.state.filter.firmwareId)
        .then((data)=>{
            if ((data.status === 200)&&(data.data.configs)){
                if (this.isMount) this.setState({configs: data.data.configs});
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    apiGetAsics(){
        getAsics('?customerId='+this.state.filter.customerId+'&firmwareId='+this.state.filter.firmwareId+'&configId='+this.state.filter.configId+'&active=1')
        .then((data)=>{
            if ((data.status === 200)&&(data.data.asics)){
                if (this.isMount) this.setState({asics: data.data.asics});
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    apiGetStats(){
        getStats('?customerId='+this.state.filter.customerId+'&firmwareId='+this.state.filter.firmwareId+'&configId='+this.state.filter.configId+'&asicId='+this.state.filter.asicId)
        .then((data)=>{
            if ((data.status === 200)&&(data.data.stats)){
                if (this.isMount) this.setState({stats: data.data.stats});
            }else{
                this.toast('Error', JSON.stringify(data.data.error));
            }          
        })
        .catch((err)=>{
            console.log(err);
            // this.props.history.push('/');
            this.toast('Error', JSON.stringify(err.message));
        });
    }


    render() {
        return (
            (!this.state.user)
            ?<div className="app"></div>
            :<div className="app">
                <Sidebar history={this.props.history} user={this.state.user} customer={this.state.customer} customerId={this.state.customerId}/>
                <div className="app-content">
                    <div className="page page-asics">
                        <div className="toasts">
                            {(this.state.toasts) &&
                                this.state.toasts.map((toast, i)=>{
                                    return <Toast key={i} id={'toast_'+i} title={toast.title} content={toast.content} />
                                })
                            }
                        </div>
                        <div className="row">
                            <div className="col">
                                <h1 className="page-title">Asic Stats
                                </h1>
                            </div>
                            <div className="col text-right">                                
                            </div>
                        </div>             
                        <div className="row mt-4">
                            <div className="col-lg-12">
                                <Filter 
                                    filter={this.state.filter}
                                    changeFilter={this.changeFilter.bind(this)}
                                    customers={(this.state.user.userType.permissions.find((el) => el.id === 58)) && this.state.customers}
                                    firmwares={(this.state.user.userType.permissions.find((el) => el.id === 55)) && this.state.firmwares}
                                    configs={(this.state.user.userType.permissions.find((el) => el.id === 56)) && this.state.configs}
                                    asics={this.state.asics}
                                />
                            </div>                                                  
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <StatsTable 
                                    stats={this.state.stats}
                                />
                            </div>
                        </div>                
                    </div>
                </div>
                
            </div>        
        );
    }
}